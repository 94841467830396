# Row action
row_action = (target) ->
  attr = target.attr('data-row-action')
  switch attr
    when 'add'
      row_add(target.closest('table'))
    when 'up'
      row_up(target.closest('tr'))
    when 'down'
      row_down(target.closest('tr'))
    when 'remove'
      row_remove(target.closest('tr'))

# Row add
row_add = (table)->
  new_row = table && table.find('tr:hidden').clone()
  if new_row
    new_row.attr('style', '')
    term_to_replace = 'replace_with_id'
    items_to_init   = new_row.find("[data-prefix]")
    if(items_to_init.length > 0)
      timestamp     = get_timestamp()
      for item in items_to_init
        item = $(item)
        item.attr('id', item.attr('id').replace(term_to_replace, timestamp))
        prefix  = item.attr('data-prefix')
        if(!prefix)
          item.attr('name', item.attr('name').replace(term_to_replace, timestamp))
        else
          item.attr('name', "#{prefix}[#{timestamp}]#{item.attr('name')}")
    table.find('tbody').append(new_row)
    check_table_reset(table)

# Row up
row_up = (row)->
  row.insertBefore(row.prev());

# Row down
row_down = (row)->
  row.insertAfter(row.next())

# Row remove
row_remove = (row)->
  table = row.closest('table')
  row.remove() if confirm("This action cannot be reverted.\n Do you want to continue ?")
  check_table_reset(table)

# Set not rows
check_table_reset = (table)->
  disabled = table.find('tbody tr').length > 0
  table.find('[data-editor-input=reset]').prop('disabled', disabled)


window.init_editor = ->
  splits = $('[data-split]')
  if(splits.length > 0)
    for item in splits
      item = $(item)
      attr = item.attr('data-split')
      splitted = item.attr(attr).split('[replace_with_id]')
      item.attr('data-prefix', splitted[0])
      item.attr(attr, splitted[1])

$ ->
  $(document).on('click', '[data-row-action]', (e)->
    row_action($(this))
  )

  init_editor()
