ReportsSelector = {
  init: ->
    $(document).on('change', '#report_id', (event) ->
      selectedId = event.target.value
      descriptionField = event.target.dataset.descriptionContainer
      if selectedId
        ReportDescription.getDescription(descriptionField, selectedId)
        AuthoritiesList.getAuthoritiesList(selectedId)
      else
        ReportDescription.clear(descriptionField)
    )
}

AuthoritiesList = {
  error: (xhrObj, textStatus, exception) ->
    alert("Sorry but something was wrong: #{exception}. Please try again or contact your administrator.")
  ,
  getAuthoritiesList: (report_id) ->
    $.ajax({
      url: "/reports/#{report_id}/update_authorities_selector",
      type: 'GET',
      dataType: 'HTML',
      timeout: 5000,
      success: (data, requestStatus, xhrObject) ->
        $('.authorities_selector').siblings('ul').html(data)
        $(LocationsList.getLocationsList)
        update_download_button()
      error: AuthoritiesList.error,
    })
  ,
  selected_authorities_ids: ->
    selectedIDs = []
    $('[authority_checkbox]:checked').each () ->
      selectedIDs.push(this.id)
    selectedIDs
}

ReportDescription = {
  getDescription: (description_container, report_id) ->
    $.ajax
      url: "/reports/#{report_id}/fetch_description",
      method: 'GET',
      timeout: 5000,
      success: (data) ->
        $(description_container).html(data.description || '-')
      error: ->
        $(description_container).html('Error while trying to load description.')
  clear: (description_container) ->
    document.querySelector(description_container).html('There is no description.')
}

LocationsList = {
  error: (xhrObj, textStatus, exception) ->
    alert("Sorry but something was wrong: #{exception}. Please try again or contact your administrator.")
  ,
  getLocationsList: ->
    $.ajax({
      url: "/reports/#{$('#report_id').val()}/update_locations_selector",
      type: 'GET',
      dataType: 'HTML',
      data: {
        selected_authorities: AuthoritiesList.selected_authorities_ids
      },
      timeout: 5000,
      success: (data, requestStatus, xhrObject) ->
        $('.locations_selector').siblings('ul').html(data)
      error: LocationsList.error,
    })
  ,
  setup: ->
    $(document).on('change', '[authority_checkbox]', LocationsList.getLocationsList)
}

update_download_button = ()->
  enabled = $('#report_from').val() && $('#report_to').val() && $('#report_id').val() && ($('[authority_checkbox]:checked').length > 0)
  disabled = if enabled then '' else 'disabled'
  $('#generate-report-btn').prop('disabled', disabled)

$ ->
  $(LocationsList.setup)
  $(ReportsSelector.init)

  $(document).on 'change', '.all-items input', () ->
    current = $(this)
    status  = current.prop('checked')
    items = current.closest('ul')
    items.find('input:not(#location_with_no_location)').prop('checked', status)

  $(document).on 'change dp.change', '#report-generator-form input', (e) ->
    update_download_button()

  # Fixme to remove when refactoring config_generator reports view.
  $(document).on 'click', '[data-instance-report-target]', (e) ->
    current = $(this)
    if current.attr('name').startsWith('select_all')
      target = current.attr('data-instance-report-target')
      checked = current.prop('checked')
      $("[data-instance-report-target=#{target}]").each () ->
        $(this).prop('checked', checked)

  $(document).on 'click', '.reports_generator li.checkbox-item', (e) ->
    e.stopPropagation()
