import_instance = (input) ->
  if input.length > 0
    url       = input.attr('data-import-instance-url')
    env       = input.attr('data-environment')
    instance  = input.attr('data-instance')
    messages = []
    messages[0] = []
    messages[0].push "You are going to overwrite '#{env}'/'#{instance}'."
    messages[0].push "Please make a database backup before continuing."
    messages[0].push "This action cannot be reverted."
    messages[0].push "Do you want to continue ?"
    messages[1] = []
    messages[1].push "Are you sure ?"
    if (url.length > 0) && confirm(messages[0].join("\n")) && confirm(messages[1].join("\n"))
      cell = input.closest('td')
      loading = cell.find('.loading')
      loading.toggle(true) if loading.length > 0
      import_link  = cell.find('.instance-action.action-import')
      import_link.toggle(false) if import_link.length > 0
      data = new FormData()
      file = input[0].files[0]
      data.append('import_app_authority', file)
      $.ajax({
        cache:        false,
        contentType:  false,
        data:         data,
        dataType:     'json',
        processData:  false,
        type:         'POST',
        url:          url,
        success:      (data, status, xhr) ->
          $('.loading').toggle(false)
          $('.instance-action.action-import').toggle(true)
          show_ajax_message("Instance #{data.code} imported", "notice")
          # e = null
          # console.log [data, status, xhr]
          # on_ajax_response(e, status, xhr, data)
        error:        (xhr, status, error) ->
          $('.loading').toggle(false)
          $('.instance-action.action-import').toggle(true)
          show_ajax_message("Instance cannot be imported", "error")
          # e = null
          # console.log [xhr, status, error]
          # on_ajax_response(e, status, xhr, error)
      });
    input.val('')

$ ->
  $(document).on 'change', '[data-import-instance-url]', (e) ->
    import_instance($(this))