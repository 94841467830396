import moment from 'moment'
import loaderImg from '../images/ajax-loader.gif'

modal_form_to_ckeck = undefined
window.wrong_dynamic_object_values = ['select', 'select...', 'select…']

window.QueryString = do ->
  # This function is anonymous, is executed immediately and
  # the return value is assigned to QueryString!
  query_string = {}
  query = window.location.search.substring(1)
  vars = query.split('&')
  i = 0
  while i < vars.length
    pair = vars[i].split('=')
    # If first entry with this name
    if typeof query_string[pair[0]] == 'undefined'
      query_string[pair[0]] = decodeURIComponent(pair[1])
  # If second entry with this name
    else if typeof query_string[pair[0]] == 'string'
      arr = [
        query_string[pair[0]]
        decodeURIComponent(pair[1])
      ]
      query_string[pair[0]] = arr
  # If third or later entry with this name
    else
      query_string[pair[0]].push decodeURIComponent(pair[1])
    i++
  query_string

String.prototype.capitalize = () ->
  this.charAt(0).toUpperCase() + this.slice(1);

String.prototype.padding = (width, character, side = 'left') ->
  text = this
  if character && (character.length > 0)
    while width > text.length
      text = if side == 'left' then (character + text) else (text + character)
  text.toString()

String.prototype.paddingLeft = (width, character=' ') ->
  this.padding(width, character)

String.prototype.paddingRight = (width, character=' ') ->
  this.padding(width, character, 'right')

String.prototype.words_length = () ->
  this.split(' ').length

window.clear_form = (form) ->
  form.find('input:not([type=submit]), textarea').val('')

window.backup_fields = (form) ->
  form.find(':input').each ->
    this.restored_value = $(this).val()

window.restore_backup_fields = (form) ->
  form.find(':input').each ->
    this.value = this.restored_value if this.restored_value

window.restore_status_or_owner_value = (application_id, element_id, element_to_find)->
  element_to_find = "[data-label='#{element_to_find}']" if element_id == 'owner_id'
  current = $("input[data-application_id=#{application_id}][id=#{element_id}]").siblings('ul').find("#{element_to_find}")
  current = current.parent() if element_id == 'status'
  dropdown = current.closest('.dropdown-select')
  current_value = current.attr('data-dropdown-value')
  current_value_label = current.attr('data-label') || current.html()
  dropdown.find('.selected_value').html(current_value_label)
  dropdown.find('input[type=hidden]').val(current_value)

window.get_timestamp = ()->
  (new Date()).getTime()

window.delete_item = (array, item) ->
  i = array.indexOf(item);
  array.splice(i, 1) if(i != -1)

window.show_shared_modal = (title="", body="", text_on_yes="Yes", text_on_no="No", action_on_yes=null, action_on_no=null, action_on_cancel=null, modal_id=null) ->
  modal = if modal_id then $("#{modal_id}") else $("#shared_dialog")
  if modal.length > 0
    modal.find(".modal-title").text(title)
    modal.find(".modal-body").html(body)
    for action_name, action_value of {yes: action_on_yes, no: action_on_no, cancel: action_on_cancel}
      button = modal.find(".option-#{action_name}")
      button.text(text_on_yes) if action_name == "yes"
      button.text(text_on_no) if action_name == "no"
      button.attr("data-action-on-click", action_value) if button

    modal.on 'shown.bs.modal', ()->
      $('body').on('click', {action_on_no: action_on_no}, handleModalCloseAction)

    modal.on 'hidden.bs.modal', () ->
      $('body').off('click', handleModalCloseAction)

    modal.modal("show")

# FIXME There is a problem here when closing shoot modal. It is being called infinite
handleModalCloseAction = (event)->
  if !$(event.target).closest('.modal').hasClass('in') && !$(event.target).is('.option-yes, .btn-accept')
    eval(event.data.action_on_no)


window.shared_dialog_one_button = (title="", body="", text_on_no="No", action_on_no=null, action_on_cancel=null, array_data=[]) ->
  modal = $("#shared_dialog_one_button")
  if modal.length > 0
    modal.find(".modal-title").text(title)
    modal.find(".modal-body").html(body)
    size = array_data.length
    if size > 0
      $( "<h5 class='subtitle-ul-modal'>Locations not approved</h5>" ).appendTo( "#shared_dialog_one_button .modal-body .approved-locations" )
      $( "<ul class='list-locations-not-approved'></ul>" ).appendTo( "#shared_dialog_one_button .modal-body .approved-locations" )
      for data in array_data
        name = data[0]
        url  = data[1]
        $( "<li><a href=#{url} target='_blank'>#{name}</a></li>").appendTo( ".list-locations-not-approved" )
    for action_name, action_value of {no: action_on_no, cancel: action_on_cancel}
      button = modal.find(".option-#{action_name}")
      button.text(text_on_no) if action_name == "no"
      button.attr("data-action-on-click", action_value) if button
    modal.modal("show")

window.show_shared_preview_invoce = (title="", body="", text_on_no="cancel") ->
  modal = $("#generic-big-modal")
  if modal.length > 0
    modal.find(".modal-title").text(title)
    modal.find(".modal-body").html(body)
    button_accept = modal.find(".btn-accept").remove()
    button_cancel = modal.find(".btn-cancel").text(text_on_no)
    modal.find(".modal-body").addClass("preview")
    modal.modal("show")

window.load_format_tools = () ->
  date_format = if window.locale && window.locale.date_format then window.locale.date_format else 'DD/MM/YYYY'
  time_format = if window.locale && window.locale.time_format then window.locale.time_format else 'HH:mm'
  datetime_format = if window.locale && window.locale.datetime_format then window.locale.datetime_format else "#{date_format} #{time_format}"
  week_start = (window.locale && window.locale.first_day_of_the_week) || 'Sunday'
  week_start = window.week_days_map[week_start.toLowerCase()] || 0
  moment.locale('en', { week: { dow: week_start } })
  $('input.datetime-picker, [data-format-type="datetime"]').datetimepicker({format: datetime_format })

  $('input.date-picker, [data-format-type="date"]').each ()->
    current = $(this)
    minDate = current.attr('minDate')
    minDate = '01/01/1950' if (current.attr('data-allow-past-date')== 'true') && !minDate

    if( minDate )
      minDate = minDate.split("/")
      limitDate = new Date(minDate[2], minDate[1]-1, minDate[0])
    else
      limitDate = new Date()
      if current.attr('value')
        date_value = current.attr('value')
        limitDate = new Date(moment(date_value, date_format))
      if limitDate > new Date()
        limitDate = new Date()
      limitDate = new Date(limitDate.getFullYear(), limitDate.getMonth(), limitDate.getDate(), 0, 0, 0, 0)
    current.datetimepicker({format: date_format, minDate: limitDate})
    current.trigger("change")

  $('[data-must-be-before], [data-must-be-after]').datetimepicker().on('dp.hide', ()->
    if Number($(this).attr('data-must-be-before'))
      element_to_limit_min_date = $(document).find("[data-line-id=#{$(this).attr('data-must-be-before')}]").find("input[name*=value]")
      element_to_limit_max_date = $(document).find("[data-line-id=#{$(this).attr('data-must-be-after')}]").find("input[name*=value]")
    else
      element_to_limit_min_date = $(document).find("[name*=#{$(this).attr('data-must-be-before')}]")
      element_to_limit_max_date = $(document).find("[name*=#{$(this).attr('data-must-be-after')}]")
    date = $(this).val().split(' ')[0] #It is to avoid having into consideration date times
    date_format = if window.locale && window.locale.date_format then window.locale.date_format else 'DD/MM/YYYY'
    new_date = new Date(moment(date, date_format))
    if element_to_limit_min_date.length > 0
      if element_to_limit_min_date.data('DateTimePicker').maxDate() && element_to_limit_min_date.data('DateTimePicker').maxDate() < new_date
        element_to_limit_min_date.data('DateTimePicker').maxDate(new_date)
      element_to_limit_min_date.data('DateTimePicker').minDate(new_date)

    if element_to_limit_max_date.length > 0
      if element_to_limit_max_date.data('DateTimePicker').minDate() && element_to_limit_max_date.data('DateTimePicker').minDate() > new_date
        element_to_limit_max_date.data('DateTimePicker').minDate(new_date)
      element_to_limit_max_date.data('DateTimePicker').maxDate(new_date)
  )

  $('input.time-picker, [data-format-type="time"]').datetimepicker({format: time_format})
  $('input.half-hour-time-picker, [data-format-type="time_half_hour"]').datetimepicker({format: time_format, stepping: 30})
  $('[data-format-type=number], [data-format-type=currency]').each ()->
    format_number_field($(this))

window.format_number_field = (current) ->
  value = (current.val() || 0).toString()
  if window.locale
    value = value.split(window.locale.currency_symbol).join("").split(window.locale.thousands_delimiter).join("").split(window.locale.decimal_separator).join(".")
  value = format_number(parseFloat(value), 'currency' == current.attr('data-format-type'))
  current.val(value)

window.parse_number = (number) ->
  if window.locale then parseFloat(number.split(window.locale.currency_symbol).join("").split(window.locale.thousands_delimiter).join("").split(window.locale.decimal_separator).join(".")) else number

window.format_number = (number, is_currency = false) ->
  result = number.toString()
  if window.locale
    is_float = (result.indexOf('.') > -1)
    number = result.split('.')
    result = number[0].replace( /\d(?=(?:\d{3})+(?:\.|$))/g, (string, index) ->
      return (string + window.locale.thousands_delimiter)
    )
    result += window.locale.decimal_separator + number[1].paddingRight(2, '0') if is_float
  if is_currency then "#{window.locale.currency_symbol}#{result}" else result

window.set_question_value = (target, data) ->
  if target && data
    target_to_update = null
    switch target.attr("data-question-type")
      when "dynamic_object"
        target_to_update = target.find("[data-dynamic-object-input='value']")
        if target_to_update.val() != data["value"]
          target_to_update.val(data["value"])
          target.find("[data-dynamic-object-input='action']").closest(".dropdown-select").find("[data-dropdown-value='Existing']").click()
          target.find("[data-dynamic-object-input='value_for_existing']").closest(".dropdown-select").find("[data-dropdown-value='#{data["value"]}']").click()
      when "assisted_dynamic_object"
        target_to_update = target.find("[data-dynamic-object-input='value']")
        if target_to_update.val() != data["value"]
          target_to_update.val(data["value"])
          target.find("[data-dynamic-object-input='action']").closest(".dropdown-select").find("[data-dropdown-value='Existing']").click()
          target.find("[data-dynamic-object-input='value_for_existing']").closest(".dropdown-select").find("[data-dropdown-value='#{data["value"]}']").click()
      when "select"
        target.find(".dropdown-select [data-dropdown-value='#{data["value"]}']").trigger("click")
      when "text", "textarea", "text_area"
        target_to_update = target.find("textarea")
        target_to_update.val(data["value"])
      when "location", "advanced location"
        option = target.find("select.location-selector option[value='#{data["location_id"]}']")
        target_to_update = target.find("input.location-name")
        target_to_update.val(option.text())
      when "radio_buttons", "radio_buttons horizontal"
        target_to_update = target.find("input[type='radio'][value='#{data["value"]}']")
        target_to_update.prop('checked',true)
      when "file"
        if data.file.url != null
          target_to_update = target.find("input[type='hidden'][name$='[import_from_QLA_ID]']")
          target_to_update.val(data['id'])
          target.find("input[type='file']").attr('data-setted', data.file.url.substr(data.file.url.lastIndexOf('/') + 1)).trigger("change")
      else
        target_to_update = target.find("input.data").last()
        target_to_update.val(data["value"]) if target_to_update.length > 0
    target_to_update.trigger("change") if target_to_update && target_to_update.length > 0 && target.attr("data-question-type") != 'date_field'


window.obtain_data = (target) ->
  result = {value: '', location_data: '', location_types: []}
  if target
    result['value'] = (target.find('input:radio:checked').val() || "").toLowerCase() if (target.hasClass('radio-area'))
    result['value'] = (target.val() || "").toLowerCase() if !result['value']
    if (target.hasClass('location-selector'))
      result['location_types'] = []
      question_scope = target.closest('.question-answer')
      new_location_type_data = question_scope.find('.location-type-selector:visible')
      if new_location_type_data.length > 0
        for location_type in new_location_type_data.val()
          location_type = location_type.trim().toLowerCase()
          result['location_types'].push(location_type) if location_type
      else
        result['location_data'] = target.find("option:selected")
        if (result['location_data'] && (result['location_data'].length > 0) && result['location_data'].attr("data-kind"))
          location_types_list = result['location_data'].attr("data-kind").split("\n")
          for location_type in location_types_list
            location_type = location_type.trim().toLowerCase()
            result['location_types'].push(location_type) if location_type
  result

window.mark_errors = (errors) ->
  $('.with-error-group').removeClass('with-error-group')
  $('.with-error-tab').removeClass('with-error-tab')
  if errors
    $.each errors, ( key, value ) ->
      key = window.current_model + '_' + key.split('.').join('_attributes_')
      current = $("##{key}")
      if(current.length > 0)
        current_container = current.closest('.form-group ')
        current_container.addClass('with-error-group') if(current_container.length > 0)
        current_panel = current.closest('.tab-item.tab-pane')
        if(current_panel.length > 0)
          current_tab = $("[href='##{current_panel.attr('id')}']")
          if(current_tab.length > 0)
            current_tab.addClass('with-error-tab')

window.modal_form = (url, title, iframe=false, modal_type='generic-big-modal', is_read_document='false', is_modal_shoots='false', dismiss=null, extra_data=null) ->
  if iframe
    set_modal_form(title, "<iframe src='#{url}' width='100%' style='height:70vh;'></iframe>",modal_type, is_read_document, dismiss)
  else
    data = {
      method: "get",
      url: url,
      data: {extra_params: extra_data}
    }
    $(document).find('#spinner, #overlay').show() if is_modal_shoots != 'false'
    $.ajax(
      data
    ).done( (result)->
      set_modal_form(title, result, modal_type, false, is_modal_shoots, dismiss)
      $(document).find('#spinner, #overlay').hide() if is_modal_shoots != 'false'
    ).fail( (result)->
      alert('Sorry but something was wrong, please try again or contact your administrator. Error ID#JS_F-SHAR_001')
      $(document).find('#spinner, #overlay').hide() if is_modal_shoots != 'false'
      console.log result
    )

window.init_ckeditor = (target) ->
  try
    if(target && target.attr('data-ckeditor-enabled') != 'true')
      target.attr('data-ckeditor-enabled', 'true')
      editor = CKEDITOR.replace(target.get(0))
      editor.on('change', ()->
        $('[data-modified-form="false"]').attr('data-modified-form', 'true')
      )
  catch e
    console.log e
  target

window.remote_tab_view = (target) ->
  if target
    tab_id = target.attr('data-remote-tab-view')
    target_panel = $("##{tab_id}")
    target_panel.html("<div class='spinner-area' style='height:100vh;background:#E0E0E0;'><img class='spinner' alt='Loading' src='#{loaderImg}' style='width:40px;height:40px;'></div>")
    $.ajax({
      method: "get",
      url:    "?tab=#{tab_id}"
    }).done( (result)->
      target.attr('data-loaded-form', 'true')
      current = $(result)
      current.find('.wysihtml5:visible').each ()->
        init_ckeditor($(this))
      set_drag_and_drop(current)
      $("##{tab_id}").html(current)
      setTimeout(()->
        initialize_main_scripts()
      , 400 )
    ).fail( (result)->
      alert('Sorry but something was wrong, please try again or contact your administrator. Error ID#JS_F-SHAR_002')
      console.log result
    )

# Function disable_submit
# - modal: the jQuery modal element to disable
# Prepare and submit the form in the modal area
window.disable_submit = (modal)->
  btn = modal.find('[data-disable-on-submit]')
  if btn.length > 0
    btn.attr('enabled', 'false')
    btn.html(btn.attr('data-disable-on-submit'))

# Function save_modal_form
# Prepare and submit the form in the modal area
window.save_modal_form = ()->
  modal   = $('#generic-big-modal:visible')
  modal.find('form[data-tmp=true]').remove()
  modal.attr('data-confirm-close', 'true')
  modal_form  = modal.find('form')
  disable_submit(modal)
  console.log('save_modal_form', "found #{modal_form.length} modals")
  if(modal_form.length > 1)
    first_modal_form = null
    modal_form.each ->
      current = $(this)
      if !first_modal_form
        first_modal_form = current.clone()
        first_modal_form.addClass('hidden')
        first_modal_form.css('display', 'none')
        modal.append(first_modal_form)
      else
        inputs = current.find('input, select, textarea').clone()
        first_modal_form.append(inputs)
    first_modal_form.find('[id]').attr('id', null)
    first_modal_form.attr('id', null)
    modal_form = first_modal_form
  console.log('save_modal_form', "used #{modal_form.length} modals")
  (modal_form.length == 1) && modal_form.submit() && disable_submit(modal)

# function set_modal_form
# - title: form header
# - content: form body
# - modal_type: the id of existing modal element to be used
# - is_read_document: if the modal contains a document with status 'read'
# - is_modal_shoots: if the modal contains a shoot or nested object element
# - dismiss: allowed accept button dismiss mode
# Show a modal popup wiht previous information.

window.set_modal_form = (title, content, modal_type, is_read_document='false', is_modal_shoots='false', dismiss=null) ->
  submit_action = "save_modal_form()"
  modal_dialog = $("##{modal_type}")
  if modal_dialog.attr('id')=='modal_document'
    if is_read_document
      modal_dialog.find('.btn-modal-message').css('display', 'none')
    else
      modal_dialog.find('.btn-modal-message').css('display', 'initial')
  modal_dialog.find(".modal-title").html(title)
  modal_dialog.find(".modal-body").html(content)
  modal_dialog.find(".btn-submit-app").attr("data-action-on-click", submit_action )
  accept_btn = modal_dialog.find(".btn-accept")
  accept_wording = if (modal_type == 'modal_document') then 'Mark as Read' else 'Accept'
  accept_btn.html(accept_wording)
  accept_btn.attr('data-dismiss', dismiss)
  accept_btn.attr("data-action-on-click", submit_action )
  accept_btn.attr("data-disable-on-submit", "<i class='fa fa-spinner fa-spin' role='img'></i> Please wait...")
  if is_modal_shoots == true
    modal_dialog.modal(backdrop:'static',"show")
  else
    modal_dialog.modal("show")
  modal_form_to_ckeck = modal_dialog
  setTimeout( ()->
    load_locations()
    init_map_answer($('.map-answer-container'))
    update_conditional_questions()
    refresh_shoot_status()
    load_format_tools()
    load_dynamic_objects()
    load_field_map()
    refresh_modal_actions()
    check_form(modal_form_to_ckeck.find('form'))
    init_editor()
    $('.wysihtml5:visible').each ()->
      init_ckeditor($(this))
  , 200 )


window.on_ajax_action = (url, data, method='get', on_finish=false)->
  $.ajax({
    method: method,
    data: data,
    url: url
  }).always( (result)->
    on_finish(result) if on_finish
  ).fail( (result)->
    console.log result
  )

# Return field input value
window.field_value= (target)->
  result = null
  current_type = target.attr("type")
  current_type = "radio" if target.hasClass('radio-area')
  switch current_type
    when "checkbox"
      result = false
      if (target.closest('.form-group').find('input.data').length > 0)
        $.each(target.closest('.form-group').find('input.data'), ()->
          result = $(this).is(':checked')
          return false if result
        )
      else
        result = target.prop("checked")
    when "radio"
      result = target.find('input:radio:checked').val()
    when "file"
      result = target.val()
      if !(result.length > 0)
        result = target.attr('data-setted')
    else
      result = target.val()
  result

window.toggle_editable = (form) ->
  is_editable = form.hasClass('editable')
  form.toggleClass('editable not-editable')
  form.find('[readonly]').attr('readonly', !is_editable)

window.scroll_to_top = ()->
  window.scrollTo(0,0)

window.refresh_modal_actions = (modal = null)->
  if modal
    submit_button = modal.find('.modal:visible .btn-accept').first()
  else
    submit_button = $('.modal:visible .btn-accept').first()
    modal = submit_button.closest('.modal:visible')
  if submit_button.length > 0
    valid = true
    if(modal.attr('data-completed-required'))
      modal.find("[data-required=true]:visible").each ()->
        valid = false if valid && !field_value($(this))
    submit_button.attr('disabled', !valid)
    submit_button.attr('enabled',   valid)
    submit_button.attr('readonly', !valid)

window.initialize_map = (container, lat, lng) ->
  if(container.length > 0)
    container.attr('data-timestamp', get_timestamp()) if !container.attr('data-timestamp')
    timestamp_code = container.attr('data-timestamp')
    if(!window.map_list[timestamp_code])
      window.map_list[timestamp_code] = {}
      latitude  = if lat then lat.val() else (window.authority_position.lat || '')
      longitude = if lng then lng.val() else (window.authority_position.lng || '')
      window.map_list[timestamp_code]['position'] = new google.maps.LatLng(latitude,longitude)
      mapOptions = {
        zoom: 10,
        center: window.map_list[timestamp_code]['position']
      }
      window.map_list[timestamp_code]['map'] = new google.maps.Map(container[0], mapOptions);
      window.map_list[timestamp_code]['marker'] = new google.maps.Marker({
        position: window.map_list[timestamp_code]['position'],
        map: window.map_list[timestamp_code]['map'],
        draggable: true
      })
      window.map_list[timestamp_code]['lat'] = lat
      window.map_list[timestamp_code]['lng'] = lng
      google.maps.event.addListener(window.map_list[timestamp_code]['marker'], "mouseup", (e) ->
        map_item = window.map_list[timestamp_code]
        map_item['lat'].val(map_item['marker'].position.lat())
        map_item['lng'].val(map_item['marker'].position.lng())
      )
      google.maps.event.addDomListener(window, 'resize', () ->
        refresh_map_list()
      )
      refresh_map_list()

window.refresh_map_list = (timestamp_codes=null) ->
  setTimeout(()->
    if window.map_list
      for timestamp_code, map_list_item of window.map_list
        google.maps.event.trigger(map_list_item['map'], 'resize')
        map_list_item['map'].setCenter(map_list_item['marker'].position);
  ,100)

window.update_map_marker = (container, lat, lng) ->
  timestamp_code = container.attr('data-timestamp')
  window.map_list[timestamp_code]['marker'].setPosition( new google.maps.LatLng( lat.val(), lng.val() ) );
  refresh_map_list()

window.load_field_map=() ->
  field_map = $('.map-field-container')
  if(field_map.length > 0)
    lat = $(field_map.attr('data-target-latitude'))
    lng = $(field_map.attr('data-target-longitude'))
    initialize_map(field_map, lat, lng)
    $(document).on 'change', lat, (e) ->
      update_map_marker(field_map, lat, lng)
    $(document).on 'keypress', lat, (e) ->
      if( e.charCode == 13 )
        e.preventDefault()
        update_map_marker(field_map, lat, lng)

    $(document).on 'change', lng, (e) ->
      update_map_marker(field_map, lat, lng)
    $(document).on 'keypress', lng, (e) ->
      if( e.charCode == 13 )
        e.preventDefault()
        update_map_marker(field_map, lat, lng)

window.show_issue_permit=(parent) ->
  $('#issue-permit-item, #create-instance-item')[0].style.display = 'initial' if $('#issue-permit-item, #create-instance-item').length > 0
window.hidden_issue_permit=(parent) ->
  $('#issue-permit-item, #create-instance-item')[0].style.display = 'none' if $('#issue-permit-item, #create-instance-item').length > 0
window.set_current_hash=() ->
  setTimeout(()->
    hash = window.location.hash.substring(1)
    selected_tab = $("ul li a[data-tab-id='#{hash}']").first()
    selected_tab.click() if selected_tab && selected_tab.length > 0
  , 100)

window.limits =(obj, limit) ->
  text = obj.val()
  length = text.length;
  if length > parseInt(limit) || length == parseInt(limit)
    $(obj).val(text.substr(0,limit)) if length > parseInt(limit)
    alert("We're sorry, the maximum length for this field is " + limit + " characters.")

String.prototype.toUnderscore = ()->
  this.replace(/\.?([A-Z]+)/g, (x,y)->
    "_" + y.toLowerCase()
  ).replace(/[^a-z0-9]/gmi, "_").replace(/_+/g,'_').replace(/^_/g,'').replace(/_$/g,'')

# To search an item with a specific attribute. It is used to know if the waiver modal should be shown
# +list_item+:: The list of the items to search the attr
# Return a if the attribute has been found.
weiver_modal_should_be_shown = (list_item)->
  list_item.find("i").attr("data-show-warning-when-is-waiver")



# It searchs if the string contains ‘new’
# +text+:: String where to find ‘new’
# Returns if matched new in text
search_status_new = (text)->
  postion = text.search("new")
  postion >= 0

# Copy the given string to the browser's clipboard
# (Works by creating a temporary textarea element, selecting & copying its text
# to the clipboard, then removing it again.)
copy_to_clipboard = (str) ->
  tmp = document.createElement('textarea')
  tmp.value = str
  document.body.appendChild(tmp)
  tmp.select()
  document.execCommand("copy")
  document.body.removeChild(tmp)

$ ->
  window.week_days_map = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6
  }

  load_format_tools()

  refresh_modal_actions()
  $(document).on 'change', '[data-required=true]:visible', ()->
    refresh_modal_actions()

  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()

  mark_errors(window.current_errors) if window.current_errors
  $("#flash-message.superadminalert .alert.alert-success").delay(2000).slideUp('slow')
  $("#flash-message .alert.alert-success").delay(5000).slideUp('slow')

  $('body').on('click', (e)->
    if ($(e.target).data('toggle') != 'popover' && $(e.target).parents('.popover.in').length == 0)
      $('[data-toggle="popover"]').popover('hide');
  )

  $(document).on("change", "[data-format-type=number], [data-format-type=currency]", ()->
    format_number_field($(this))
  )

  old_dropdown_value = undefined

#  It is raised when focus on some element with the attribute [data-ajax-action-on-change]
  $(document).on('focus', "[data-ajax-action-on-change]", ()->
    if $(this).find('i').length > 0
      old_dropdown_value = "." + $(this).find('i').attr('class').split(" ").join('.')
    else
      if $(this).find('.selected_value').length > 0
        old_dropdown_value = $(this).find('.selected_value').html()
  ).on('change', "[data-ajax-action-on-change]", ()->
    current = $(this)
    url = current.attr('data-ajax-action-on-change')
    method = current.attr('data-ajax-method')
    lock = current.attr('lock')
    current_waiver_application = false
    action_on_yes = "on_ajax_action('#{url}', '#{current.serialize()}', '#{method}'); "
    action_on_no = "restore_status_or_owner_value('#{current.attr('data-application_id')}', '#{current.attr('id')}', '#{old_dropdown_value}');"
    field_to_change = ''

    if (current.attr('name') == 'application[status]')
      field_to_change = 'status'
      value = current.val()
      issue_permit_id = $(this).parent().parent().siblings('.permit-info-area').find('#issue-permit-item').attr('id') || $(this).parent().parent().siblings('.permit-info-area').find('#create-instance-item').attr('id')
      #Tratment of the colour bar on the left of the stripview
      application_id = current.attr('data-application_id')
      row = $(document).find("[data-main-url*=" + (application_id.toLowerCase()) + "], [data-main-url*=" + (application_id.toUpperCase()) + "]")
      if (application_id != null) && (row.length) > 0
        toogle_status = "if ($(document).find(\"[data-main-url*=#{application_id.toLowerCase()}]\").length>0){" +
        "$(document).find(\"[data-main-url*=#{application_id.toLowerCase()}]\").attr('class', '#{row.attr('class').replace(/status-(\w.[^ ]*)/g, "status-#{current.val().replace(' ', '-')}")}')}" +
        " else {$(document).find(\"[data-main-url*=#{application_id.toUpperCase()}]\").attr('class', '#{row.attr('class').replace(/status-(\w.[^ ]*)/g, "status-#{current.val().replace(' ', '-')}")}')}; "
        lock = true
      list_item_selected = current.siblings('ul').find("[data-dropdown-value=#{value}]") if value == "current" || value == "approved"
      switch value
        when 'approved'
          current_waiver_application = weiver_modal_should_be_shown(list_item_selected)
          action_on_yes += "show_issue_permit('#{issue_permit_id}');"
        when 'current'
          current_waiver_application = weiver_modal_should_be_shown(list_item_selected)
          issue_permit_id = $(this).parent().parent().siblings('.permit-info-area').find('#issue-permit-item').attr('id')
          action_on_yes += "hidden_issue_permit('#{issue_permit_id}');"
        when 'review', 'declined', 'cancelled'
          title = "Please indicate to the applicant why the application is changing to #{value}. The text below will be sent to the applicant by email."
          new_message_url = current.attr('data-new-message-url') + "&application_status=#{value}"
          action_on_yes += "modal_form('#{new_message_url}', '#{title}');"
        when 'info req'
          if $(document).find("[id=#{window.id_of_the_shoot}]").find("[name$='[database_action]']").is(':checked')
            title = "Please indicate to the applicant why the application is changing to #{value}. The text below will be sent to the applicant by email."
            new_message_url = current.attr('data-new-message-url') + "&shoot_to_update=#{window.id_of_the_shoot.split("_").pop()}"
            action_on_yes += "modal_form('#{new_message_url}&cause=The location has to be changed', '#{title}');"
            action_on_no += " $(document).find(\"[id=#{window.id_of_the_shoot}]\").find(\"[name$='[database_action]']\").prop('checked', false); window.id_of_the_shoot = '';"
          else
            title = "Please indicate to the applicant why the application is changing to #{value}."
            info_req_cause_url = current.attr('data-action-on-info-req')
            new_message_url = current.attr('data-new-message-url')
            action_on_yes += "modal_form('#{info_req_cause_url}', '#{title}', false ,'not-closable-modal', false, false, null, '#{new_message_url}'); "
        else
          issue_permit_id = $(this).parent().parent().siblings('.permit-info-area').find('#issue-permit-item').attr('id')
          action_on_yes += "hidden_issue_permit('#{issue_permit_id}');"

    else if (current.attr('name') == 'application[owner_id]')
      field_to_change = 'owner'
      lock = false if lock == true
    if lock == true
      action_on_yes = "on_ajax_action('#{url}', '#{current.serialize()}', '#{method}', function(data){#{action_on_yes}}); #{toogle_status};"
    old_status_value_new = search_status_new(old_dropdown_value)
    if current_waiver_application && old_status_value_new
      show_waiver_modal("<b>Waiver Application</b>", action_on_yes, action_on_no)
      lock = true
    else
      show_shared_modal("Application #{field_to_change} change", "Please confirm you want to save these changes", "Yes", "No", action_on_yes, action_on_no, action_on_no)
      lock = true
  )

  $(document).on 'change', '[maxlength]', () ->
    limits($(this), $(this).attr('maxlength'))

  $(document).on("click", ".modal", (e)->
    setTimeout( ()->
      if ($('.modal:visible').length > 0) && !$('body').hasClass('modal-open')
        $('body').addClass('modal-open')
    , 500)
  )

  $(document).on("click", "[data-action-on-click]:not([readonly=readonly],[enabled=false],[disabled=true])", (e) ->
    current = $(this)
    action = current.attr("data-action-on-click")
    if action.indexOf('submit') > -1
      if !check_form($(this).parents('.modal-dialog').find('form')) && (!window.confirm("We're sorry. Some fields are missing required data. Press cancel to continue editing or ok to save with incomplete data."))
        $('#generic-big-modal').attr('data-confirm-close', 'false')
        e.preventDefault
      else
        eval(action)
    else
      eval(action)
      if action.indexOf('window.location') > -1
        current.closest('.modal').addClass('do-not-close')
        current.hide()
        current.parent('.navbar-btn').addClass('align-middle').css('justify-content', 'flex-end')
        current.siblings('#footer_container').addClass('fa fa-spinner fa-2x fa-pulse fa-fw')
        current.siblings('#footer_container').attr('role', 'img')
        current.siblings('button').attr('disabled', true)
  )

  $(document).on("click", "[data-action-on-click][readonly=readonly],[data-action-on-click][enabled=false],[data-action-on-click][disabled=true]", (e) ->
    e.stopImmediatePropagation()
    e = $.event.fix(e)
    e.preventDefault()
    e.returnValue = false
  )

  $(document).on("click", "[data-modal-body]", (e)->
    e.preventDefault()
    current = $(this)
    if !current.attr('disabled')
      modal_dialog = $("#generic-modal")
      modal_dialog.attr('data-completed-required', current.attr('data-completed-required'))
      modal_dialog.find(".modal-title").html( current.attr("data-modal-title")  || "Notice" )
      modal_dialog.find(".modal-body").html(  current.attr("data-modal-body")   || "No message" )
      if current.attr('data-modal-accept-button')
        modal_dialog.find(".btn-accept").attr('data-modal-accept-button',  current.attr('data-modal-accept-button'))
      if current.attr('data-modal-cancel-button')
        modal_dialog.find(".btn-cancel").attr('data-modal-cancel-button',  current.attr('data-modal-cancel-button'))
      if current.attr('data-modal-buttons-align')
        modal_dialog.find(".navbar-btn").css("text-align", current.attr('data-modal-buttons-align'))
      modal_dialog.find(".btn-accept").html(  current.attr("data-modal-accept") || "Accept" )
      modal_dialog.find(".btn-accept").hide() if current.attr("remove-data-modal-accept") =='yes'
      modal_dialog.find(".btn-cancel").html(  current.attr("data-modal-cancel") || "Cancel" )
      if current.attr("data-modal-accept-href")
        action_on_accept = "window.location.href = '#{current.attr("data-modal-accept-href")}'"
      else
        action_on_accept = current.attr("data-modal-accept-action") || ""
      if current.attr("data-modal-cancel-href")
        action_on_cancel = "window.location.href = #{current.attr("data-modal-cancel-href")}"
      else
        action_on_cancel = current.attr("data-modal-cancel-action") || ""

      modal_dialog.find(".btn-accept").attr(  "data-action-on-click", action_on_accept)
      modal_dialog.find(".btn-cancel").attr(  "data-action-on-click", action_on_cancel)

      modal_dialog.find(".btn-accept").attr(  "data-method", current.attr("data-modal-accept-method") || "get" )
      modal_dialog.find(".btn-cancel").attr(  "data-method", current.attr("data-modal-cancel-method") || "get" )
      modal_dialog.modal("show")
      setTimeout( ()->
        refresh_modal_actions()
      , 200 )
  )

  $(document).on("click", "[data-dropdown-value]", (e)->
    current = $(this)
    element = current.find('i')
    if element.hasClass('no-allow-current-application')
      if element.attr("data-approved-locations") != ""
        data = element.attr("data-approved-locations")
        data_array = JSON.parse(data)
        shared_dialog_one_button("Application","<div class='approved-locations'> <p>Sorry, the status of this application cannot be changed until the location has been added to the location database, categorised as a synonym or removed.</p></div>", "Ok", null, null, data_array)
    else
      dropdown = current.closest('.dropdown-select')
      current_value = current.attr('data-dropdown-value')
      current_value_label = current.attr('data-label') || current.html()
      dropdown.find('.selected_value').html(current_value_label)
      dropdown.find('input[type=hidden]').val(current_value)
      dropdown.find('input[type=hidden]').trigger('change')
  )

  $(document).on("click", "[data-modal-form]", (e)->
    e.preventDefault()
    current = $(this)
    url     = current.attr("data-modal-form")
    title   = current.attr("data-modal-form-title")
    iframe  = current.attr("data-as-iframe") == "true"
    modal_type = current.attr("data-modal-type")
    is_read_document = current.attr('data-document-read') == "true"
    is_modal_shoots = current.closest('#shoots_collapsable').length > 0
    dismiss = current.attr("data-dismiss")
    modal_form(url, title, iframe, modal_type, is_read_document, is_modal_shoots, dismiss)
  )

  $(document).on("click", "[data-main-url]", (e)->
    target = $(e.target)
    if !(target.closest('.dropdown-select').length > 0) && !(target.closest('i').length > 0)
      current = $(this)
      url = current.attr('data-main-url')
      window.location.href = url if url
  )

  $(document).on("change", "form[data-autosubmit-on-change='true']", (e)->
    current = $(this)
    if !current.attr('id')
      console.log('Not ID defined for this item')
    else
      action_on_yes = "$('##{current.attr('id')}').submit(); "
      target = $(e.target)
      if (target.length > 0) && (target.attr('id') == 'application_status')
        value = target.val()
        if (value == 'review') || (value == 'declined') || (value == 'info req')
          new_message_url = target.attr('data-new-message-url')
          title = 'Please indicate to the applicant why the application is changing to info required. The text below will be sent to the applicant by email.'
          action_on_yes += "modal_form('#{new_message_url}', '#{title}');"
      show_shared_modal("Please confirm!", "Do you want save these changes?", "Yes", "No", action_on_yes)
  )

  $(document).on 'click', '.toggle-editable', ()->
    current = $(this)
    form = current.closest('form')
    toggle_editable(form)

  $(document).on 'click', 'a[readonly=readonly], a[enabled=false], a[disabled=true]', (e)->
    e.preventDefault()
    return false

  $(document).on 'change', '#cause_selector', (e)->
    value_selected = $('#cause_selector').find(":selected").text()
    $('#cause_selector').closest('.modal').attr('data-confirm-close', 'true').modal('hide')
    new_msg_url = $('#cause_selector').closest('.modal').find('#new_message_url').val()
    unless $('#cause_selector').find(":selected").val() == 'not_show'
      new_msg_url +=  "&cause=#{value_selected}"
    modal_form("#{new_msg_url}", "Please indicate to the applicant why the application is changing to info required. The text below will be sent to the applicant by email.");

  $('#generic-big-modal').on('hide.bs.modal', (e) ->
    current = $(this)
    if(current.find('form[data-name-object="shoot"]').length > 0)
      confirm = current.attr('data-confirm-close') == 'true'
      if !confirm
        set_to_close  = "$('#generic-big-modal').attr('data-confirm-close', 'true')"
        action_on_yes = "#{set_to_close}; $('#generic-big-modal .btn.btn-accept').click();"
        action_on_no  = "#{set_to_close}; $('#generic-big-modal .btn.btn-cancel').click();"
        show_shared_modal("Save changes", "Would you like to save the information entered?", "Yes", "No", action_on_yes, action_on_no)
      else
        current.attr('data-confirm-close', 'false')
      return confirm
  )

  $('#generic-modal').on('hide.bs.modal', (e) ->
    if $(this).hasClass('do-not-close')
      e.preventDefault()
      e.stopImmediatePropagation()
  )

  $('#not-closable-modal').on('hide.bs.modal', (e) ->
    if $(this).attr('data-confirm-close') == 'true'
      $(this).attr('data-confirm-close', 'false')
    else
      false
  )

  $(document).on('focusin', '[data-validation]', ->
    $(this).data('val', $(this).val())
  ).on('change','[data-validation]', ->
    prev = $(this).data('val')
    value = $(this).val()
    try
      if !eval($(this).attr('data-validation'))
        alert('Field format is not correct. Please follow instructions in the help box.')
        $(this).val(prev).effect( "highlight", {color:"rgb(242, 222, 222)"}, 2000 )
    catch e
      console.log(e)
  )

  if window.location.hash
    set_current_hash()

  setTimeout( () ->
    scroll_to_top()
  ,10)

  $(document).on('click', 'li:not(.active) [data-remote-tab-view]', (e)->
    current = $(this)
    modified = $('[data-modified-form]')
    if (modified.attr('data-modified-form') == 'false')
      if (current.attr('data-loaded-form') != 'true')
        remote_tab_view(current)
    else
      txt = "You should save the changes before opening another tab.\nDo you want to continue ?"
      if confirm(txt)
        modified.attr('data-modified-form', 'false')
        current.click()
  )
  $(document).on('hide.bs.tab', '[data-modified-form="true"] [data-remote-tab-view]', (e)->
    e.preventDefault()
  )
  $(document).on('change', '[data-modified-form] input, [data-modified-form] select, [data-modified-form] textarea', (e)->
    $('[data-modified-form="false"]').attr('data-modified-form', 'true')
  )
  $('[data-remote-tab-view]:first').click()

  $(document).on('click', '[copy-to-clipboard]', (e)->
    e.preventDefault()
    copy_to_clipboard(this.dataset.text)
  )
