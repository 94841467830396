// Load all the controllers within this directory and all subdirectories.
// Controller files must be named component_controller.ts.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()

//ViewComponents stimulus controllers
const context = require.context("../../../components", true, /_controller\.ts$/)

application.load(definitionsFromContext(context))
window.Stimulus = application
