window.refresh_shoot_status = (form = null)->
  status = "complete"
  if form
    status_input = form.find('#shoot_status')
  else
    status_input = $('#shoot_status')
    form = status_input.closest('form')
  if status_input.length > 0
    form.find("[data-required=true]:visible").each ()->
      if status == "complete"
        status = "incomplete" if !field_value($(this))
    status = 'incomplete' if missing_value_in_simple_location(form.find('.street-area:visible'))
    status_input.val(status)

missing_value_in_simple_location = (street_area)->
  return false unless street_area.length
  return false unless street_area.parent().siblings('.location-selector[data-required=true]').length

  missing_value = true
  street_area.find('.location-street-name').each ()->
    missing_value = false if !!field_value($(this))
  return missing_value

$ ->
  refresh_shoot_status()

  # When the generic-big-modal has been close (shoots or event modal from applicant view), the html body is set to blank
  $('#generic-big-modal').on('hidden.bs.modal', () ->
    $(this).find('.modal-body').html('')
  )

  $(document).on('submit', '#generic-big-modal form[id^="new_shoot"], #generic-big-modal form[id^="edit_shoot"]', ()->
    refresh_shoot_status()
  )

  $(document).on('click', '#admin-shoot-title .glyphicon-triangle-bottom', ()->
    item_to_load = $('[data-load-on-click]').parent($(this).attr('data-target')).find('[data-load-on-click]')
    load_shoot_info(item_to_load, item_to_load.attr('data-load-on-click')) unless item_to_load.attr('already-loaded')
  )

  shoot_rows = $('.application_shoots_list').find('tr')
  check_shoot_status(row) for row in shoot_rows

check_shoot_status = (row) ->
  not_marked_as_error = $('.application_shoots_list').find('.uncompleted-shoot-message').length == 0
  if $(row).hasClass('status-incomplete') && not_marked_as_error
    html_phrase = '<span class="uncompleted-shoot-message">There are one or more ' + pluralize_name(shoot_type_name(row)) +
    ' that are incomplete. Please answer all the required ' + shoot_type_name(row) + ' questions.</span>'

    $('.application_shoots_list').find('table').after(html_phrase)

shoot_type_name = (row) ->
  nearest_shoot_type_title = $(row).closest('.tab-item').find('.section-title')
  sanitize_name(nearest_shoot_type_title.text())

sanitize_name = (name) ->
  text_is_plural = name.slice(-1) == 's'

  if text_is_plural
    name = name.slice(0, -1)

  name.toLowerCase()

pluralize_name = (name) ->
  name + 's'

load_shoot_info = (target, url) ->
  if(target && url)
    request = {
      method: "get",
      url: url
    }
    target.parent().siblings('.spinner, .ui-widget-overlay').show(100)
    $.ajax(
      request
    ).done( (result)->
      set_drag_and_drop(result)
      target.hide()
      target.html(result)
      setTimeout( ()->
        load_locations()
        init_map_answer($('.map-answer-container'))
        update_conditional_questions(target)
        refresh_shoot_status()
        load_format_tools()
        load_dynamic_objects()
        load_field_map()
        refresh_modal_actions()
        force_update_show_when()
        $('.wysihtml5:visible').each ()->
          init_ckeditor($(this))
        target.attr('already-loaded', true).show(900)
        target.parent().siblings('.spinner, .ui-widget-overlay').hide(800)
      , 200 )
    ).fail( (result)->
      target.parent().siblings('.spinner, .ui-widget-overlay').hide()
      alert('Sorry but something was wrong, please try again or contact your administrator.')
      console.log result
    )
