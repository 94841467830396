import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['newRegionSlug', 'multiRegionModal', 'regionPlate']
  static classes = ['activePlate']

  declare newRegionSlugTarget: HTMLInputElement
  declare multiRegionModalTarget: HTMLDivElement
  declare regionPlateTargets: HTMLDivElement[]

  declare activePlateClass: string

  connect() {
    $(`#${this.multiRegionModalTarget.id}`).modal('show');
  }

  selectRegion(event: any) {
    const regionPlate = event.currentTarget
    this.regionPlateTargets.forEach((plate) => {
      plate.classList.remove(this.activePlateClass)
    })

    regionPlate.classList.add(this.activePlateClass)

    this.newRegionSlugTarget.value = regionPlate.dataset.regionSlug
  }
}
