# Checks if the recipients field from the new message form (as an official reviewing an application, the Message tab)
# is valid whenever there is user input. It will show two errors on the form if so, and remove them is the information is correct.
class RecipientsValidator
  setup_validators: ->
    $(document).on 'blur', '.full-mail-contacts-text', () ->
      handle_email_errors()
    $(document).on 'click', '#message_sent_to_applicant', () ->
      handle_email_errors()

  handle_email_errors = () ->
    window.contact_list_length = $('.full-mail-contacts-text').val().trim().length
    window.is_send_to_applicant_checked = $('#message_sent_to_applicant').is(':checked')

    split_emails = $('.full-mail-contacts-text').val().trim().split(',')
    invalid_emails = split_emails.filter (email) -> !is_valid(email)
    new_message_length = $("#new_message").find('trix-editor').text().trim().length

    if invalid_emails.length == 0 || window.contact_list_length == 0
      remove_invalid_messages()
    else
      show_invalid_email_messages()

    set_new_message_submit_availability(new_message_length)

  is_valid = (email) ->
    send_to_applicant_only = window.is_send_to_applicant_checked && window.contact_list_length == 0

    send_to_applicant_only || /\S+@\S+\.\S+/.test(email)

  show_invalid_email_messages = () ->
    errors_are_hidden = $('.additional_information_to_show.recipient-error').length == 0

    if errors_are_hidden
      text_area_message = 'It looks like there’s something other than email addresses in the recipients textbox.
                Even small things in this textbox (like starting a new paragraph)
                can create trouble when we try to send your message. To send this message,
                please remove anything which is not an email address from the textbox.'
      submit_message = 'Please review what you have entered in the email address text box.'

      input_field = $('.full-mail-contacts-text')
      submit_button_container = $("#new_message").find('input.btn-lg').closest('.actions-area')

      input_field.after(recipient_error(text_area_message))
      submit_button_container.before(recipient_error(submit_message))

  recipient_error = (message) ->
    return '<p class="additional_information_to_show recipient-error">' + message + '</p>'

  remove_invalid_messages = () ->
    $('.additional_information_to_show.recipient-error').remove()

$ ->
  recipients_validator = new RecipientsValidator
  recipients_validator.setup_validators()
