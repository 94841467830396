import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['commentInput']

  declare commentInputTarget: HTMLTextAreaElement

  clearInput() {
    this.commentInputTarget.value = ''
  }
}
