# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

load_locations = () ->
  data=[];
  $("#application_location_id option").each ->
    id = $(this).val();
    name = $( this ).text();
    synonyms = $( this ).attr("data-synonyms");
    tmp={
      'value': id,
      'label': name,
      'synonyms':synonyms
    };
    data.push(tmp);

  $( "#location_text" ).autocomplete({
    minLength: 0,
    source: data,
    focus: ( event, ui ) ->
      $( "#location_text" ).val( ui.item.label );
      return false;

    select: ( event, ui ) ->
      $( "#location_text" ).val( ui.item.label )
      $( "#location_text_id" ).val( ui.item.value );
      $( "#synonyms" ).html( ui.item.synonyms );

      return false;
  })
  .autocomplete( "instance" )._renderItem = ( ul, item ) ->
    return $( "<li><div class='autocomplete-item'><div class='autocomplete-label'>#{item.label}</div><div class='autocomplete-synonyms'>#{item.synonyms}</div></div></li>" ).appendTo( ul );

manage_location_form = (name) ->
  # FIXME:  What is this function for? Maybe it is not used.
  find_value = false
  locations_information = ""
  $("#application_location_id option").each ->
    if ($(this).text() == name)
      find_value = $(this).val()
      locations_information=$(this).attr("data-location_information")
  $('#location_text').focus()
  $('#suggestion').toggle(!find_value)
  $("#box_helper").toggle(!find_value)
  if(find_value)
    $("#box_helper").html("click here to download a PDF with more location info"+ " " + locations_information)
    $("select#application_location_id").val(find_value)
    $('#box_helper').css({ 'background' : 'rgb(255, 247, 198) none repeat scroll 0% 0%', 'border' : '1px solid rgb(255, 217, 40)', 'margin-top' : '10px', 'padding' : '5px', 'margin-bottom' : '10px' })
    $("#text_info_location").hide()
  else
    if($("#location_text").text=="")
      $("#box_helper").toggle()
      $("#text_info_location").show()
    else
      $("#box_helper").html( "That location's not in our database. Please select 'NOT IN LIST - FIND ON MAP' from the " +
        "dropdown below")
      $('#box_helper').css({ 'background' : 'rgb(255, 247, 198) none repeat scroll 0% 0%', 'border' : '1px solid rgb(255, 217, 40)', 'margin-top' : '10px', 'padding' : '5px' })
      $("#text_info_location").hide()
  $("#box_helper").show()

autofill_location = (on_load=false) ->
  latitude_field  = $("#location_latitude")
  longitude_field = $("#location_longitude")
  if !on_load || (latitude_field.length > 0 && !latitude_field.val() && longitude_field.length > 0 && !longitude_field.val())
    address = []
    $('[data-address-info="true"]').each ->
      local_value = $(this).val()
      address.push(local_value) if local_value
    address.push(window.current_country)    if window.current_country
    address.push(window.current_city_area)  if window.current_city_area
    window.geocoder = new google.maps.Geocoder() if !window.geocoder
    window.geocoder.geocode( { 'address': address.join(", ") }, (results, status)->
      if status == "OK"
        position = results[0].geometry.location
        latitude_field.val(position.lat())
        longitude_field.val(position.lng())
        longitude_field.trigger("change")
    )


# Get geocode from address
window.get_geocode = (address, name=null, callback=null) =>
  geocodes = get_geocodes();
  geoKey = name || address;
  if( address && !geocodes[geoKey] )
    if !name
      geocodes[geoKey] = {lat: "0", lng: "0"}
      callback && callback(geocodes[geoKey])
    else
      window.geocoder = window.geocoder || new google.maps.Geocoder();
      window.geocoder.geocode( { 'address': address }, (results, status) =>
        position = (status == "OK") && results && results[0] && results[0].geometry && results[0].geometry.location;
        geocodes[geoKey] = {
          lat:  position && position.lat() || (window.authority_position && window.authority_position.lat) || "0",
          lng:  position && position.lng() || (window.authority_position && window.authority_position.lng) || "0"
        };
        callback && callback(geocodes[geoKey])
      );
  else
    callback && callback(geocodes[geoKey])

#  Get all geocodes json conversion
window.get_geocodes = () =>
  def = {
    null: {
      lat:  (window.authority_position && window.authority_position.lat) || "0",
      lng:  (window.authority_position && window.authority_position.lng) || "0"
      }
  };
  window.geocodes = window.geocodes || def

$ ->

  window.map_list = {}
  setTimeout( ()->
    load_locations()
    load_field_map()
  , 200 )

  $('.map-field-container').each ->
    current = $(this)

  $(document).on 'click', '[data-location_id]', ->
    $('#location_text').val($(this).attr("data-location_name"))
    $("#application_location_id").val($(this).attr("data-location_id"))
    link_value = $(this).text()
    locations_information = ""
    $("#application_location_id option").each ->
      this_select = $(this).text()
      if ($.trim(this_select.toString()) == $.trim(link_value.toString()))
        locations_information = $(this).attr("data-location_information")
    $("#box_helper").show();
    $("#box_helper").html( "click here to download a PDF with more location info"+ " " + locations_information)
    $('#box_helper').css({ 'background' : 'rgb(255, 247, 198) none repeat scroll 0% 0%', 'border' : '1px solid rgb(255, 217, 40)', 'margin-top' : '10px', 'padding' : '5px' });
    $("#addnewlocation").hide()

  $(document).on 'change', '#location_text', (e) ->
    manage_location_form($(this).val());

  $(document).on 'click', '.autocomplete-item', (e) ->
    manage_location_form($(this).find('.autocomplete-label').html());

  $(document).on 'change', '[data-address-info="true"]', (e) ->
    autofill_location()

  $(document).on 'keypress', '[data-address-info="true"]', (e) ->
    if( e.charCode == 13 )
      e.preventDefault()
      autofill_location()


  $(document).on 'change', '#suggestion_id', (e) ->
    locations_information = ""
    value_suggestion = $("#suggestion_id option:selected").text()
    if ($.trim(value_suggestion.toString()) == $.trim('NOT IN LIST - FIND ON MAP'))
      $("#addnewlocation").toggle()
      $("#box_helper").toggle();
      $("#suggestion_id").show()
    else
      $("#application_location_id option").each ->
        if ($.trim($(this).text().toString()) == $.trim(value_suggestion.toString()))
          locations_information = $(this).attr("data-location_information")
      $("#addnewlocation").hide()
      $("#suggestion_id").hide()
      $("#location_text").val($("#suggestion_id option:selected").text())
      $("#box_helper").html("click here to download a PDF with more location info"+ " " + locations_information)
      $('#box_helper').css({ 'background' : 'rgb(255, 247, 198) none repeat scroll 0% 0%', 'border' : '1px solid rgb(255, 217, 40)', 'margin-top' : '10px', 'padding' : '5px' });
      $("#box_helper").show();

  autofill_location(true)

  $(document).on 'click', '#admin-shoot-title a.warning', (e)->
    e.preventDefault()
    element = $(this)
    if element.siblings('[data-toggle]').attr('aria-expanded') == undefined || element.siblings('[data-toggle]').attr('aria-expanded') == 'false'
      element.siblings('[data-toggle]').click()
    $('html,body').animate { scrollTop: $(element.attr('href')).offset().top - 40 }, 1000


  $(document).on 'change', 'input[name*=\'database_action\']', ()->
    if $(this).val() == 'synonym'
      $(this).closest('.location-section-approbation').find('.synonyms_area').show(300)
    else
      $(this).closest('.location-section-approbation').find('.synonyms_area').hide(300)
      window.id_of_the_shoot = $(this).closest('form').attr('id')
      if $(this).val() == 'not_valid' && $("[name = 'application[status]']").length
        $('html, body').animate({ scrollTop: $("[name = 'application[status]']").offset().top - $(this).offset().top }, 1000)
        setTimeout( ()->
          $('[name = "application[status]"]').effect( "highlight", {color:"rgba(238, 120, 120, 0.2)"}, 2000 )
          $(document).find('[name = "application[status]"]').focus()
          $(document).find('[name = "application[status]"]').siblings('ul').find('[data-dropdown-value="info req"]').click()
        ,1300)
