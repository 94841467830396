# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

formatter_to_two_decimals = new (Intl.NumberFormat)('en-US',
  minimumFractionDigits: 2
  maximumFractionDigits: 2)


window.load_suffix_invoice = () ->
  $( "#invoice_suffix" ).autocomplete({
    source: window.suffix
  })

window.clickAndShowSpinner = () ->
  $('.modal.fade.in').modal('hide')
  $('#spinner, #overlay').show()

# Create a new invoice item for an invoice
# element : element that is going to be cloned
# location_required is used for addding styles
window.add_new_invoice_item = (element, location_required = true) ->
  table = $(element).parent().siblings('table')
  invoice_items_list = table.find('.invoice_items_list')
  new_invoice_item = $(element).closest('form').parent().find('.hidden_invoice_item').first()
  if(new_invoice_item.length > 0)
    new_invoice_item = new_invoice_item.clone()
    new_invoice_item.toggleClass('hidden_invoice_item visible_invoice_item')
    new_invoice_item.toggle(true)
    replace_invoice_item_id(new_invoice_item)
    new_invoice_item.find(".reset-required").val("")
    new_invoice_item.find("[data-invoice-item-field=description]").html("")
    new_invoice_item.find(".reset-required input").val("")
    new_invoice_item.find(".reset-required select").val("")
    new_invoice_item.find(".invoice-item-location").css("display", "none") if !location_required
    new_invoice_item.find("[data-invoice-item-field=id]").remove()
    new_invoice_item.hide()
    invoice_items_list.append(new_invoice_item)
    new_invoice_item.show(900)

# Replace id and name for a new invoice item
window.replace_invoice_item_id = (item) ->
  html      = item.html()
  just_now  =  $.now()
  exp_id    = /invoice_items_attributes_/g
  txt_id    = "invoice_items_attributes_"
  exp_name  = /\[invoice_items_attributes\]\[/g
  txt_name  = "[invoice_items_attributes]["
  html      = html.replace(exp_id,"#{txt_id}#{just_now}").replace(exp_name,"#{txt_name}#{just_now}");
  item.html(html)

set_fixed_invoice_items_price = (invoice_item) ->
  kind = invoice_item.find("[data-invoice-item-field=kind] .selected_value").html().trim()
  price_per_unit_field = invoice_item.find("[data-invoice-item-field=price_per_unit]")
  found = []
  for global_item in itemsWithFixedAmount
    item_splitted = global_item.split('|')
    if kind.includes 'non taxed'
      unless item_splitted.includes('taxable')
        if item_splitted[0] == kind.replace('- (non taxed)','').trim()
          price_per_unit_field.val(item_splitted[1])
          found = item_splitted
          break
    else
      if item_splitted.includes('taxable')
        if item_splitted[0] == kind
          price_per_unit_field.val(item_splitted[1])
          found = item_splitted
          break

  if found.length
    if found.includes('not_editable')
      invoice_item.find("[autodisable=true]").each ()->
        $(this).attr({readonly: true, enabled: false}).addClass('enable-effect')
      invoice_item.find("[data-invoice-item-field=number_days]").val(1)
      invoice_item.find("[data-invoice-item-field=number_items]").val(1)
    else
      invoice_item.find("[autodisable=true]").each ()->
        $(this).attr({readonly: false, enabled: true})
      price_per_unit_field.attr({readonly: true, enabled: false}).addClass('enable-effect')
  else
    invoice_item.find("[autodisable=true]").each ()->
      $(this).attr({readonly: false, enabled: true})

  update_invoice_total(invoice_item)
  price_per_unit_field.trigger("change")

update_invoice_total = (invoice_item) ->
  p_p_unit = parse_number(invoice_item.find("[data-invoice-item-field=price_per_unit]").val() || '1')
  days = parse_number(invoice_item.find("[data-invoice-item-field=number_days]").val() || '1')
  items = parse_number(invoice_item.find("[data-invoice-item-field=number_items]").val() || '1')
  invoice_item.find("[data-invoice-item-field=amount]").val(Math.round(p_p_unit*days*items*100)/100).trigger('change')

update_invoice_item = (invoice_item) ->
  kind = invoice_item.find("[data-invoice-item-field=kind] .selected_value").html()
  amount_field = invoice_item.find("[data-invoice-item-field=amount]")
  vat_field = invoice_item.find("[data-invoice-item-field=vat]")
  total_field = invoice_item.find("[data-invoice-item-field=total]")
  amount  =   parse_number amount_field.val()
  vat_percent = parse_number amount_field.attr('data-vat-percent')

  vat = parse_number((amount * (vat_percent / 100)).toString())
  if itemsWithVAT.indexOf(kind) > -1
    total = vat + amount
  else
    vat = 0
    total = amount

  vat_field.val(formatter_to_two_decimals.format(vat.toFixed(5)))
  total_field.val(formatter_to_two_decimals.format(total.toFixed(5)))
  vat_field.trigger("change")
  total_field.trigger("change")

update_invoice_summary = (tbody, summary_div) ->
  total = subtotal = vat = 0
  tbody.find('tr:visible').each ()->
    subtotal += parse_number($(this).find('[data-invoice-item-field="amount"]').val())
    vat += parse_number($(this).find('[data-invoice-item-field="vat"]').val())
    total += parse_number($(this).find('[data-invoice-item-field="total"]').val())
  summary_div.find('.subtotal').val(subtotal.toFixed(2)).trigger("change")
  summary_div.find('.subtotal').html(summary_div.find('.subtotal').val())
  summary_div.find('.total').val(total.toFixed(2)).trigger("change")
  summary_div.find('.total').html(summary_div.find('.total').val())
  summary_div.find('.vat').val(vat.toFixed(2)).trigger("change")
  summary_div.find('.vat').html(summary_div.find('.vat').val())

showInvoicesList = {
  showInvoicePreview: (data, requestStatus, xhrObject) ->
    show_shared_preview_invoce("Invoice Preview", data, "Go back")
  ,

  error: (xhrObj, textStatus, exception) ->
    alert('Sorry but something was wrong, please try again or contact your administrator.')
  ,
  getInvoiceForm: ->
    form =  $('#new_invoice')
    action = form.attr( 'action' )
    $.ajax({
      url: action + '/preview_html',
      type: 'GET',
      data: form.serialize(),
      timeout: 10000,
      success: showInvoicesList.showInvoicePreview,
      error: showInvoicesList.error
    })
  ,
  setup: ()->
    $('.invoice_preview').on('click', showInvoicesList.getInvoiceForm)
}

$ ->
  showInvoicesList.setup()
  load_suffix_invoice()

  $(document).on 'click', '#checkout-button', ->
    pk = $(this).attr('public_key')
    if $(this).attr('stripe_account')
      stripe = Stripe(pk, {stripeAccount: $(this).attr('stripe_account')})
    else
      stripe = Stripe(pk)

    stripe.redirectToCheckout(sessionId: $(this).attr('stripe_session_id')).then (result) ->
      return

  $(document).on "click", ".btn_create_items", (e)->
    e.preventDefault()
    element = $(this)
    add_new_invoice_item(element, true)

  $(document).on('change', '[data-invoice-item-field=price_per_unit], [data-invoice-item-field=number_days], [data-invoice-item-field=number_items]', ()->
    update_invoice_total($(this).closest("tr"))
  )

  $(document).on('change', '[data-invoice-item-field=amount], [data-invoice-item-field=kind]', (e) ->
    update_invoice_item($(this).closest("tr"))
    update_invoice_summary($(this).closest('tbody'), $(this).closest('.form-group').find('#invoice-summary'))
  )
  $(document).on('change', '[data-invoice-item-field=kind]', () ->
    set_fixed_invoice_items_price($(this).closest("tr"))
  )

  $(document).on('click', '[data-name-object="invoice"] [data-toggle="collapse"]', ()->
    $(this).closest('.list-group-item').find('.invoice-footer:not(.new-invoice-footer)  .invoice-footer-item').toggle()
  )

  #Make uneditable invoice elements
  $(document).on('click', '[data-target*="invoice-item-"]', ()->
    $(this).closest('.list-group-item').find('.invoice-header-actions').toggle(300)
    draft_invoice = $(this).parents('#draft-invoices')
    if draft_invoice.length > 0
      form = draft_invoice.find($(this).closest('[id*= "edit_invoice_"]'))
      form.find('[enabled= true ]').attr({readonly: true, disabled: true, enabled: false})
      form.find('.add-item').css("display", "none")
    else
      sent_or_paid_invoice = $(this).parents('#sent-invoices, #paid-invoices')
      form = sent_or_paid_invoice.find($(this).closest('.list-group-item'))
      form.find('#invoice_po_number, #invoice_note').css('background-color', 'transparent').attr({readonly: true, disabled: true, enabled: false})
      form.find('#invoice_suffix').siblings('button').css('background-color', 'transparent').attr({readonly: true, disabled: true, enabled: false})
      if $(this).parents('#paid-invoices').length > 0
        form.find("[form-item-type='invoice_payments_readonly'] [id$='_notes']").css('background-color', 'transparent').attr({readonly: true, disabled: true, enabled: false})
    restore_backup_fields(form)
  )

  # Make editable invoice elements
  $(document).on('click', '.invoice-edit a.enable-editing', ()->
    element =  $(this).closest('.invoice-edit')
    element = $(this).parents('.invoice-view-detail') if !element.length > 0 && $(this).parents('#sent-invoices').length > 0
    item = element.siblings().find('#invoice_po_number')
    item = element.parent().siblings().find('#invoice_po_number')
    form = element.parents('#draft-invoices').find(element.closest('[id*= "edit_invoice_"]'))
    if item.attr 'readonly'
      if form.length >= 1     # For draft invoices - All items are editables but vat and total
        backup_fields(form)
        form.find('.add-item').show(600)
        form.find('* [disabled]').not('[data-invoice-item-field="vat"]').not('[data-invoice-item-field="total"]')
          .attr({disabled: false, readonly: false, enabled: true})
        form.find('.invoice-body-item tbody tr.visible_invoice_item').each ()->
          set_fixed_invoice_items_price($(this))
      else                    # For sent invoices - Editable elements are 'po_number and suffix'
        form = element.parents('#sent-invoices, #paid-invoices').find(element.closest('[id*= "edit_invoice_"]'))
        backup_fields(form)
        item.css('background-color', '#f5f5f5').attr({disabled: false, readonly: false, enabled: true})
        form.find('#invoice_suffix, #invoice_note').css('background-color', '#f5f5f5').attr({disabled: false, readonly: false, enabled: true})
        form.find('#invoice_suffix').siblings('button').css('background-color', '#f5f5f5').attr({disabled: false, readonly: false, enabled: true})
        if element.parents('#paid-invoices').length > 0
          form.find("[form-item-type='invoice_payments_readonly'] [id$='_notes']").css('background-color', '#f5f5f5').attr({disabled: false, readonly: false, enabled: true})
  )

  #Show spinner / hide modals when an invoice is submitted.
  $(document).on('submit', "*[id^='edit_invoice_'], [id='new_invoice']", ()->
    $('.modal.fade.in').modal('hide')
    $('#spinner, #overlay').show()
  )


  $(document).on('mouseenter', '.auto-update-title', ()->
    $(this).attr('title', $(this).val())
  )

  $('.hidden_invoice_item').each ()->
    current = $(this)
    current.find('* [disabled]').not('[data-invoice-item-field="vat"]').not('[data-invoice-item-field="total"]').attr({disabled: false, readonly: false, enabled: true})
    current.toggle(false)
    current.insertAfter(current.closest('form'))

  $(document).on('click', '.remove-invoice-item', ()->
    unless typeof $(this).attr('disabled') != typeof undefined && $(this).attr('enabled') != 'true'
      current_tab = $(this).closest('form').attr('id');
      if current_tab == 'new_invoice'
        $(this).parents('tr').hide('100', ->
          update_invoice_summary($(this).closest('tbody'), $(this).closest('.form-group').find('#invoice-summary'))
          $(this).closest('tr').remove();
        )
      else
        $(this).siblings('input').attr('value', 1)
        $(this).parents('tr').hide('100', ->
          update_invoice_summary($(this).closest('tbody'), $(this).closest('.form-group').find('#invoice-summary'))
        )
  )
  $('.cancel-invoice-link, .resend-invoice-link, .delete-invoice-link').on('click', (e)->
    element = $(this)
    data = ''
    element.prop('class').split(' ').forEach (classElement) ->
      if classElement.indexOf('-invoice-link') > 0
        data = classElement.substring(0, classElement.indexOf('-invoice-link'))

    if !confirm("Please confirm you want #{data} this invoice")
      e.preventDefault()
      e.stopPropagation()
    else
      $('.modal.fade.in').modal('hide')
      $('#spinner, #overlay').show()
  )

  add_new_invoice_item({id: 'btn_create_item_'}, true)



