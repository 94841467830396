window.SkipToConfig = {
    settings: {
        skipTo: {
            headings: "h1, h2",
            landmarks: '[role="main"]',
            displayOption: 'popup',
            customClass  : "skipToMenu",
            colorTheme: "aria",
            attachElement: "header"
        }
    }
}
