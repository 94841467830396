window.refresh_message_button = (form = null)->
  if form.length > 0
    valid = true
    form.find("[data-required=true]").each () ->
      editor = $(this).siblings().find('iframe')
      if editor.length > 0
        return(valid = false) if ($(this).siblings().find('iframe').contents().find('body').html().replace('<br>','').length < 8)
      else
        return(valid = false) if !field_value($(this))
    form.find('[type=submit]').prop('disabled', !valid)

window.delete_value_textarea = (textarea, pattern) ->
  textarea_value = textarea.val()
  escaped_pattern = pattern.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
  reg_exp = new RegExp(escaped_pattern + "[,]?" + "[ ]?")

  textarea.val(textarea_value.replace(reg_exp, ''))


$ ->
  refresh_message_button($('#new_message'))
  $(document).on 'change', '#message_template_id', ()->
    if $(this).siblings().find('span').html() == 'Consultation'
      $('#message_due_date').closest('.form-group').show(700)
    else
      $('#message_due_date').closest('.form-group').hide(700)

  $(document).on 'click', '.add-document-to-message', ()->
    $(document).find('.file-field-for-messages').click()

  $(document).on 'keyup', '#new_message', ()->
    refresh_message_button($(this))

  $(document).on 'change', '#new_message', ()->
    refresh_message_button($(this))

  $(document).on 'change', '.mail-contacts-selects .dropdown-select input[type="hidden"]', ()->
    current   = $(this)
    new_mails = current.val()
    if(new_mails.length > 0)
      textarea  = current.closest('.mail-contacts-area').find('.full-mail-contacts-text')
      old_mails     = textarea.val()
      new_mails = ", #{new_mails}" if(old_mails.length > 0)
      textarea.val(old_mails + new_mails)
      current.closest('.mail-contacts-area').find('[data-dropdown-value=""]').click()


  editor = CKEDITOR.instances.message_notes
  if(editor)
    editor.on 'change', ()->
      refresh_message_button($(document).find('#new_message'))
      editor.resetDirty()

  $('.multiselect li').on 'click', (e)->
    if !$(this).hasClass('ANContact')
      e.stopPropagation()
      $(this).find('input[type="checkbox"]').click()

  $('.multiselect input[type="checkbox"]').on 'click', (e)->
    e.stopPropagation()
    el = $(this)
    if el.is(':checked')
      new_mail = el.val()
      textarea  = el.closest('.mail-contacts-area').find('.full-mail-contacts-text')
      old_mails = textarea.val()
      if old_mails
        new_mails = if (old_mails.lastIndexOf(',') != (old_mails.length-2) && (old_mails.length > 0)) then ', ' else ''
        new_mails = new_mails + "#{new_mail}"
        textarea.val(old_mails + new_mails)
    else
      new_mails = el.val()
      textarea  = el.closest('.mail-contacts-area').find('.full-mail-contacts-text')
      textarea_value = textarea.val()
      #found the email in the textarea
      if textarea_value && (textarea_value.indexOf(new_mails)!=-1)
        #delete the email in the textarea
        delete_value_textarea(textarea, new_mails)

  $("button[name='all_contacts']").on 'click', (e)->
    textarea = $('.full-mail-contacts-text')
    textarea_value = textarea.val()
    $('.multiselect input[type="checkbox"]').each ->
      element = $(this)
      if element.is(':checked')
        element.prop('checked', false) if(textarea_value.lastIndexOf(element.val()) == -1)
