# Drag & Drop customizations
window.set_drag_and_drop = (target)->
  if target
    target = $(target)
    set_draggable(target)
    set_droppable(target)

# set_draggable
window.set_draggable = (target)->
  target.find(".composer-item.composer-sidebar .q-element-container.draggable-box").draggable({
    # cursorAt: { top: 10, left: 100 },
    connectToSortable: ".droppable-box",
    helper: "clone",
    handle: ".q-element-action-handle",
    cursor: "move",
    opacity: 0.7,
    revert: true,
    revertDuration: 100
  });

# set_droppable
window.set_droppable = (target)->
  target.find(".droppable-box").sortable({
    connectWith: ".droppable-box",
    placeholder: "ui-state-highlight",
    handle: ".q-element-action-handle",
    revert: 100,
    stop: (event, ui)->
      update_builder_data($(ui.item[0]))
    update: (event, ui) ->
      # Required in the future
  });

# Set builder element data
update_builder_data = (qelement)->
  refresh_positions(qelement)
  action_name = if(qelement.attr('data-code')) then 'refresh' else 'new'
  qelement_action(qelement, action_name)

# Refresh positions
refresh_positions = (qelement)->
  container   = qelement.parent()
  parent_id   = container.closest('.q-element-container[data-code]').attr('data-code')
  position_x  = container.closest('.q-element-container[data-code=' + parent_id + '] [data-position-x-code]').attr('data-position-x-code')
  position_y  = (parseInt(qelement.prev().attr('data-position-y')) || 0) + 1
  qelement.attr('data-parent-id',   parent_id)
  qelement.attr('data-position-x',  position_x)
  refresh_next_positions(qelement, position_y)

# Refresh next positions
refresh_next_positions = (qelement, position_y)->
  if qelement.length > 0
    qelement.attr('data-position-y',  position_y)
    refresh_next_positions(qelement.next(), position_y + 1)

# Execute element action buttons
qelement_action = (qelement, action)->
  ajax_args = {
    url:      null,
    method:   null,
    callback: null,
    dataType: null,
    data:     {},
  }
  questionnaire_id = qelement.closest('.main-workspace-area').find('> input[type="hidden"]').val()
  switch action
    when 'new'
      ajax_args.url       = window.qelement_path
      ajax_args.method    = 'post'
      ajax_args.dataType  = 'json'
      ajax_args.data.questionnaire_element = {}
      ajax_args.data.questionnaire_element.questionnaire_id = questionnaire_id
      ajax_args.data.questionnaire_element.element_id   = qelement.attr('data-element-id')
      ajax_args.data.questionnaire_element.position_x   = qelement.attr('data-position-x')
      ajax_args.data.questionnaire_element.position_y   = qelement.attr('data-position-y')
      ajax_args.data.questionnaire_element.parent_id    = qelement.attr('data-parent-id')
      ajax_args.callback = (qelement, result)->
        qelement.attr('data-config-url', result.url)
        qelement.attr('data-code', result.id)
    when 'refresh'
      ajax_args.url       = qelement.attr('data-config-url')
      ajax_args.method    = 'put'
      ajax_args.dataType  = 'json'
      ajax_args.data.questionnaire_element = {}
      ajax_args.data.questionnaire_element.questionnaire_id = questionnaire_id
      ajax_args.data.questionnaire_element.element_id   = qelement.attr('data-element-id')
      ajax_args.data.questionnaire_element.position_x   = qelement.attr('data-position-x')
      ajax_args.data.questionnaire_element.position_y   = qelement.attr('data-position-y')
      ajax_args.data.questionnaire_element.parent_id    = qelement.attr('data-parent-id')
      ajax_args.callback = (qelement, result)->
        qelement.attr('data-config-url', result.url)
        qelement.attr('data-code', result.id)
    when 'config'
      ajax_args.url     = "#{qelement.attr('data-config-url')}/edit"
      ajax_args.method  = 'get'
      if ajax_args.url
        code            = qelement.attr('data-code')
        parent_id       = qelement.attr('data-parent-id')
        config_title    = qelement.attr('data-config-title') || 'Config Element'
        ajax_args.callback = (qelement, result)->
          set_modal_form(config_title, result, 'generic-big-modal')
        if(!code)
          ajax_args.data.element_id       = qelement.attr('data-element-id')
          ajax_args.data.questionnaire_id = questionnaire_id
    when 'clone'
      ajax_args.url       = window.qelement_path
      ajax_args.method    = 'post'
      ajax_args.dataType  = 'json'
      ajax_args.data.questionnaire_element = {}
      ajax_args.data.questionnaire_element.source_id  = qelement.attr('data-code')
      ajax_args.data.questionnaire_element.element_id = qelement.attr('data-element-id')
      ajax_args.data.questionnaire_element.questionnaire_id = questionnaire_id
      ajax_args.callback = (qelement, result)->
        new_qelement = qelement.clone()
        new_qelement.attr('data-config-url', result.url)
        new_qelement.attr('data-code', result.id)
        new_qelement.after(element)
    when 'remove'
      return true if(!window.confirm("This action cannot be reverted.\nDo you want to continue ?"))
      ajax_args.url       = qelement.attr('data-config-url')
      ajax_args.method    = 'delete'
      ajax_args.dataType  = 'json'
      ajax_args.callback  = (qelement, result)->
        qelement.remove()

  if ajax_args.url
    $.ajax(
      ajax_args
    ).done( (result)->
      ajax_args.callback && ajax_args.callback(qelement, result)
    ).fail( (result)->
      alert('Sorry but something was wrong, please try again or contact your administrator. Error ID#JS_F-COMP_001')
    )
  else
    alert('Sorry but something was wrong, please try again or contact your administrator. Error ID#JS_F-COMP_002')

# Execute builder action buttons
run_composer_action = (builder, action)->
  # root_element = builder.find('.composer-element-main > [data-element-type]')
  # switch action
  #   when 'save'
  #     $('form[data-name-object="appauthority"]').submit()
  #     element.remove() if(window.confirm("Do you really want to remove this item?"))
  #   when 'clone'
  #     element.after(element.clone())
  #   when 'config'
  #     element.find('.element-config-area:first').toggle()

$ ->
  set_drag_and_drop($('body'));
  # Element Actions
  $(document).on('click', '[data-qelement-action]', (e)->
    current = $(this)
    element = current.closest('.q-element-container.draggable-box')
    action  = current.attr('data-qelement-action')
    qelement_action(element, action)
  );
  # Composer Actions
  $(document).on('click', '[data-composer-action]', (e)->
    current = $(this)
    action  = current.attr('data-composer-action')
    builder = $('.questionnaire-composer')
    run_composer_action(builder, action);
  );
