import { Controller } from 'stimulus'

type AjaxResponseType = {
  data: {
    full_message: string
  }
}

export default class extends Controller {
  static targets = ['output']

  declare outputTarget: HTMLElement

  addNewLine(e: CustomEvent<AjaxResponseType>) {
    const line = e?.detail?.data?.full_message

    line && this.outputTarget.insertAdjacentHTML('afterbegin', line)
  }
}
