import moment from "moment"

window.restore_document_values = (form) ->
  form.find(':input').each ->
    if $(this).attr("type") == 'text'
      $(this).val('')

  form.find(':button').siblings("[name='document[shoot_id]']").parent().find(':button span').html('None')
  form.find(':button').siblings("[name='document[document_type]']").parent().find(':button span').html('Select document type')

  form.find(':button span').parent().siblings().val('')
  form.find(':button').parent().siblings().html(form.find(':button span').html())
  form.find('.file-name').css('display', 'hidden').html('')
  form.find('[data-setted]').val('') if form.find('[data-setted]').length > 0
  form.find('input#document_file').attr('data-setted','') if form.find('input#document_file').length > 0

window.refresh_document_status = (form)->
  if form && (form.length > 0)
    status = null
    form.find("[data-required=true]").each ()->
      current = $(this)
      status = 'true' if !field_value(current)
    form.find(".upload-doc").attr('disabled', status)

window.add_new_document = (parent_element, info, url_prefix) ->
  element_to_copy = parent_element.find('.panel-default.hidden')

  if element_to_copy.length > 0
    new_document = element_to_copy.clone()
    new_document.toggleClass('hidden visible')
    src = info['file'].url
    parent_element.find('.docs_number').html(parseInt(parent_element.find('.docs_number').html())+1)
    img_name = if info['can_be_previewed?'] then src.substr(info['file'].url.lastIndexOf('/') + 1) else ''
    format = src.substr(info['file'].url.lastIndexOf('.') + 1)
    src = '/assets/pdf.png' if format == 'pdf'
    alt = img_name.lastIndexOf('.') + 1
    img = "<img alt=#{alt} class='img-thumbnail' src=#{src}>"

    new_document.find('.col-xs-5').html(img)
    new_document.find('.col-xs-5').toggleClass('hidden visible')

    new_document.find(".type").html("#{info['document_type']}")

    new_document.find(".docname").find('a').html(img_name)

    new_document.find(".docname").find('a').attr('data-modal-form', "#{info['file'].url}")
    new_document.find(".docname").find('a').attr('data-modal-form-title', "<h4><b>#{info['name']}</b></h4>")

    new_document.find(".name").html("#{info['name']}")

    mark_as_read_url= "/documents/#{info.id}/mark_as_read"
    new_document.find(".docname").find('a').attr('data-document-read-url', "#{url_prefix+mark_as_read_url}")

    mark_as_archived_url="/documents/#{info.id}?document%5Barchived%5D=true"
    new_document.find('.archive_doc').find('a').attr('data-method', 'put').attr('rel', 'nofollow').attr('href', "#{url_prefix+mark_as_archived_url}")

    time = moment(info.created_at).format("DD MMM YYYY")
    new_document.find(".doc-add-by").html("Added by <b> #{info['user']['first_name']} #{info['user']['last_name']}</b> #{time}")

    new_document.hide()
    where_the_element_goes = parent_element.find('#uploaded-docs')
    where_the_element_goes.prepend(new_document)
    where_the_element_goes.find('.panel-default').first().show(600)
    $(document).find('[data-tab-id=documents]').siblings('.red_circle').css('display', 'block')

showDocumentList = {
  hideDocumentList: ->
    $('#documents-lateral-panel').hide()
    $(window).off('click')
    $('dropdown').off('show.bs.dropdown')
  ,
  showDocumentDiv: (data, requestStatus, xhrObject) ->
    $('#top-documents').children().addClass('open') unless $('#top-documents').children().hasClass('open')
    $('#documents-lateral-panel').html(data).show()
    $(window).on('click', (e)->
      if !$(e.toElement).closest('.document-panel').length > 0 || $(e.toElement)[0].hasAttribute('data-close-documents')
        showDocumentList.hideDocumentList()
      else
        $('#top-documents').children().addClass('open') unless $('#top-documents').children().hasClass('open')
    )
    $('.dropdown').on('show.bs.dropdown', (e)->
      showDocumentList.hideDocumentList()
    )
  ,
  updateDocumentDiv: (data, requestStatus, xhrObject) ->
    $('#documents-lateral-panel').html(data).show()
  ,
  error: (xhrObj, textStatus, exception) ->
    alert('Sorry but something was wrong, please try again or contact your administrator.')
  ,
  getDocumentsList: ->
    $.ajax({
      url: '/documents/documents_lateral_panel',
      type: 'POST',
      data:
        document_type: $(this).attr('id'),
        current_authority: window.current_authority,
        current_app: window.current_app,
        applications: window.applicationsIDList
      ,
      dataType: 'html',
      timeout: 10000,
      success: showDocumentList.showDocumentDiv
      error: showDocumentList.error
    })
  setup: ->
    $('#top-documents').on('click', showDocumentList.getDocumentsList)
}

$ ->
  $(showDocumentList.setup)
  refresh_document_status($('form[data-name-object="document"]'))
  $(document).on 'change', 'form[data-name-object="document"]', (e)->
    refresh_document_status( $(this).closest('form') )

  # Add functionality to allow multiple clicks on add new document
  $(document).on( 'change', '[type="file"]', ()->
    element = $(this).closest('.drag-and-drop-area').siblings('.file-name')
    element.css('display', 'initial').effect( "highlight", {color:"rgb(95, 181, 90, 0.3)"}, 2000 )
    if $(this).val().length > 0
      element.html($(this).val().replace(/C:\\fakepath\\/i, ''))
      $(this).attr('data-setted', $(this).val())
    else if $(this).attr('data-setted') && $(this).attr('data-setted').length > 0
      element.html($(this).attr('data-setted'))
  )

  $(document).on('change', '[type="file"].file-field-for-messages', ()->
    $(this).closest('form').submit() if $(this).val()
  )

  $(document).on( 'change', '[name="document[document_type]"]', ()->
    $(this).parent().siblings().text($(this).val())
  )

  $(document).on( 'click', '.btn-modal-message', ()->
    url = window.document_read_url
    if url
      $.ajax({
        method: 'get',
        url: url
      }).success((data)->
        $('#modal_document').modal('hide')
      )
  )

  $(document).on('click', '#click-documents', ()->
    $('[data-tab-id="documents"]').click()
  )

  $(document).on('click', '[data-document-read-url]', ()->
    current = $(this)
    target = current.attr('data-document-read-url')
    window.document_read_url = target
  )


  $(document).on('click', '#download-all-docs-link', (e)->
    downloadIframe = document.getElementById('download-iframe')
    e.preventDefault()
    url = this.href

    downloadIframe.onload = ->
      try
        iframeDocument = downloadIframe.contentDocument or downloadIframe.contentWindow.document
        jsonResponse = JSON.parse(iframeDocument.body.innerText)
        if jsonResponse.error
          alert "Error: #{jsonResponse.error}"
      catch e
        # Download was successful
    downloadIframe.src = url
  )
