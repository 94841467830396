class DropdownToggleItemListener
  constructor: (elements) ->
    window.elements = elements

  key_code_to_action = (focused_element, key_code) ->
    if key_code == 'ArrowDown'
      focused_element.next().find('li').first().focus()

  add_event_to_element = (element) ->
    element.keyup (e) ->
      key_code_to_action(element, e.key)

  setup_listeners: ->
    add_event_to_element($(element)) for element in window.elements

$ ->
  dropdown_toggle_item_listener = new DropdownToggleItemListener($('.dropdown-toggle'))
  dropdown_toggle_item_listener.setup_listeners()
