# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

delay = do ()->
  timer = 0
  (callback, ms) ->
    clearTimeout timer
    timer = setTimeout(callback, ms)

window.onloadAuthoritySelector = ()->
  $('[id^="instances_"], [id^="region_"]').on 'shown.bs.collapse', (e) ->
    $(this).parent().find("[href='#" + ($(this).attr('id')) + "']").find('.fa-play').addClass('fa-rotate-90') if $(this).hasClass('in')

  $('[id^="instances_"], [id^="region_"]').on 'hidden.bs.collapse', (e) ->
    $(this).parent().find("[href='#" + ($(this).attr('id')) + "']").find('.fa-play').removeClass('fa-rotate-90') if !$(this).hasClass('in')

  $('#authority-selector').on 'hide.bs.modal', (e) ->
    $(this).find('.authority-table').collapse('show') if !$(this).find('.authority-table').hasClass('in')
    $(this).find('.authority-hierarchy, .authority-search-form').collapse('hide') if $(this).find('.authority-hierarchy').hasClass('in')

  $authority_filter_rows = $('.border-bottom')

  $('#authority_filter').keyup ->
    val = $.trim(@value).toUpperCase()
    delay (->
      if val == ''
        $authority_filter_rows.find("[aria-expanded='true']").click()
        $authority_filter_rows.show(300)
      else
        $authority_filter_rows.hide(100)
        $("[data-search*='#{val.toUpperCase()}']").each () ->
          string = $(this).next().attr('id') || $(this).next().attr('id')
          string = if string then string.substr(0, string.indexOf('_')) else 'authority'
          switch string
            when 'region'
              $(this).show(300)
              $(this).closest('.panel-collapse').siblings().show(300)
              if !$(this).closest('.panel-collapse').hasClass('in')
                instance_id_to_search = $(this).closest('.panel-collapse').attr('id')
                $(this).closest('.panel-collapse').siblings().find("[href='##{instance_id_to_search}']").click()
              $(this).siblings().find('.border-bottom').show(300)
            when 'instances'
              $(this).show(300)
            else
              $(this).show(300)
              $(this).parent().siblings().show(300)
              $(this).closest('.panel-collapse').siblings().show(300)
              if !$(this).closest('.panel-collapse').hasClass('in')
                instance_id_to_search = $(this).closest('.panel-collapse').attr('id')
                $(this).closest('.panel-collapse').siblings().find("[href='##{instance_id_to_search}']").click()
                region_id_to_search = $(this).parent().attr('id')
                $(this).closest('.panel-collapse').find("[href='##{region_id_to_search}']").click()
    ), 400


showAuthoritySelector = {
  fillModalContent: (data, requestStatus, xhrObject) ->
    $('#authority-selector').find('.modal-body').html(data)
    $(document).ready onloadAuthoritySelector
  ,
  error: (xhrObj, textStatus, exception) ->
    alert('Sorry but something was wrong, please try again or contact your administrator.')
  ,
  getAuthoritiesList: ->
    $.ajax({
      url: '/authorities/show_authorities_list',
      type: 'GET',
      data:
        current_authority: window.current_authority,
        current_app: window.current_app,
      ,
      dataType: 'html',
      timeout: 10000,
      success: showAuthoritySelector.fillModalContent
      error: showAuthoritySelector.error
    })
  setup: ->
    $("[data-target='#authority-selector']").on('click', showAuthoritySelector.getAuthoritiesList)
}

$ ->
  $(showAuthoritySelector.setup)
