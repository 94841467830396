/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

const jQuery = require('jquery')
require('jquery-ui')

window.$ = jQuery
window.jQuery = jQuery

require('custom-event-polyfill')
require('jquery-ujs')
require('jquery-ujs-events-polyfill')

require('moment')
require('jquery-remotipart')
require('trix')

require('bootstrap')
require('bootstrap-datetimepicker')

window.CKEDITOR_BASEPATH  = '/packs/js/ckeditor/';
require('ckeditor4')

require('./controllers')

require('./application.scss')

require('../../assets/javascripts/administrator.js.coffee')
require('../../assets/javascripts/applicant_message_listener.js.coffee')
require('../../assets/javascripts/applications.js.coffee')
require('../../assets/javascripts/applications_item_listener.js.coffee')
require('../../assets/javascripts/authorities.js.coffee')
require('../../assets/javascripts/bootstrap.js.coffee')
require('../../assets/javascripts/button_listener.js.coffee')
require('../../assets/javascripts/calendar.js.coffee')
require('../../assets/javascripts/calendar_entries.js.coffee')
require('../../assets/javascripts/components.js.coffee')
require('../../assets/javascripts/documents.js.coffee')
require('../../assets/javascripts/dropdown_item_listener.js.coffee')
require('../../assets/javascripts/dropdown_toggle_listener.js.coffee')
require('../../assets/javascripts/dynamic_objects.js.coffee')
require('../../assets/javascripts/editors.js.coffee')
require('../../assets/javascripts/groups.js.coffee')
require('../../assets/javascripts/instances.js.coffee')
require('../../assets/javascripts/internal_messages.js.coffee')
require('../../assets/javascripts/invoices.js.coffee')
require('../../assets/javascripts/locations.js.coffee')
require('../../assets/javascripts/messages.js.coffee')
require('../../assets/javascripts/questionnaire_answers.js.coffee')
require('../../assets/javascripts/questionnaire_elements.js.coffee')
require('../../assets/javascripts/recipients_validator.js.coffee')
require('../../assets/javascripts/reminders.js.coffee')
require('../../assets/javascripts/reports.js.coffee')
require('../../assets/javascripts/shared.js.coffee')
require('../../assets/javascripts/shoots.js.coffee')
require('../../assets/javascripts/skipToMenu_config.js')
require('../../assets/javascripts/skipToMenu.js')
require('../../assets/javascripts/submission_fee.js.coffee')
require('../../assets/javascripts/users.coffee')
require('../../assets/javascripts/answer_notifications.js.coffee')
require('../../assets/javascripts/dashboard.js.coffee')

// wice_grid
require('../../assets/javascripts/wice_grid/wice_grid_processor.js.coffee')
require('../../assets/javascripts/wice_grid/wice_grid_init.js.coffee')
require('../../assets/javascripts/wice_grid/wice_grid_saved_queries_init.js.coffee')

// Images
require.context('../../assets/images', true, /\.(?:png|jpg|gif|ico|svg)$/)
// Fonts
require.context('../../assets/fonts', true, /\.(?:ttf|eot|woff|woff2|svg)$/)

//ViewComponents assets
require.context('../../components', true, /\.(?:sass|scss)$/)

// to handle JavaScript automatically use this, we will have to configure it for ViewComponents in the future
// let webpackContext = require.context('../../assets/javascripts', true, /\.js$/)
// for(let key of webpackContext.keys()) { webpackContext(key) }

