# update_show_when
window.update_show_when = ->
  # Get all modified inputs
  updates_map = {}
  $('[data-show-when-id][data-aelement-value][data-aelement-change=true]').each ->
    current = $(this)
    current.attr('data-aelement-change', 'false')
    key = current.attr('data-show-when-id')
    updates_map[key] = current.attr('data-aelement-value') if(!updates_map[key])
  # Get all elements which use modified inputs in 'show when'
  selector_prefix = "[data-show-when-info][data-show-when-info*='"
  selentor_sufix  = "']"
  keys    = Object.keys(updates_map)
  search  = "#{selector_prefix}#{keys.join(selentor_sufix + "," + selector_prefix)}#{selentor_sufix}"
  # Check each target element
  $(search).each ->
    current         = $(this)
    sub_search      = []
    show_when_info  = current.attr('data-show-when-info').split(';')
    if show_when_info.length > 0
      for index of show_when_info
        show_when_item    = show_when_info[index]
        [element, value]  = show_when_item.split(':')
        sub_search.push("[data-show-when-id='#{element}'][data-aelement-value='#{value.toLowerCase()}']")
    sub_search      = sub_search.join(',')
    shown           = sub_search && $(sub_search).length > 0
    current.toggle(shown) if(current.is(':visible') != shown)
  true

# force_update_show_when
window.force_update_show_when = ->
  $('[data-aelement-change]').attr('data-aelement-change', 'true')
  update_show_when()

# get_qelement_value
window.get_qelement_value = (target) ->
  result = null
  if target
    qelement_kind = target.attr('data-qelement-kind')
    switch qelement_kind
      when 'dynamic'
        target_value_input = target.find("[data-dynamic-object-element-input=existing_value]")
        result = target_value_input.val() if target_value_input.length > 0
      when 'location'
        target_value_input = target.find('[data-qelement-input=value]')
        result = target_value_input.val() if target_value_input.length > 0
      else
        target_value_input = target.find('[data-qelement-input=value]')
        if target_value_input.length > 0
          if target_value_input.attr('type') == 'checkbox'
            result = target_value_input.prop('checked')
          else
            result = target_value_input.val()
        else
          target_value_input = target.find("input[type='radio'][value]:checked")
          result = target_value_input.val() if target_value_input.length > 0
  result

# set_qelement_basic_value
set_qelement_basic_value = (target, value) ->
  if target
    qelement_kind = target.attr('data-qelement-kind')
    switch qelement_kind
      when 'dynamic'
        existing_value_input = target.find("[data-dynamic-object-element-input=existing_value]")
        current_action = 'existing'
        existing_action_input = target.find("[data-dynamic-object-element-input=action]")
        existing_action_input.val(current_action)
        existing_value_input.val(value)
      when 'location'
        target_value_input = target.find('[data-qelement-input=value]')
        target_value_input.val(value) if target_value_input.length > 0
      else
        target_value_input = target.find('[data-qelement-input=value]')
        if target_value_input.length > 0
          if target_value_input.attr('type') == 'checkbox'
            target_value_input.prop('checked', value == true)
          else
            target_value_input.closest(".dropdown-select").find("[data-dropdown-value='#{value}']").click()
            target_value_input.val(value)
        target_value_alternative = target.find("input[type='radio'][value='#{value}']")
        if target_value_alternative.length > 0
          target_value_alternative.prop('checked', true)
        target_import_input = target.find("[ddata-qelement-input=import_from]")

# set_qelement_value
window.set_qelement_value = (target, data) ->
  if target && data
    qelement_kind = target.attr('data-qelement-kind')
    switch qelement_kind
      when 'dynamic'
        existing_value_input = target.find("[data-dynamic-object-element-input=existing_value]")
        if existing_value_input.val() != data.value
          current_action = 'existing'
          existing_action_input = target.find("[data-dynamic-object-element-input=action]")
          existing_action_input.val(current_action)
          existing_action_input.closest(".dropdown-select").find("[data-dropdown-value='#{current_action}']").trigger('click')
          existing_action_input.trigger('change')
          existing_value_input.val(data.value)
          existing_value_input.closest(".dropdown-select").find("[data-dropdown-value='#{data.value}']").trigger('click')
          existing_value_input.trigger('change')
      when 'location'
        target_value_input = target.find('[data-qelement-input=value]')
        if target_value_input.length > 0
          target_value_input.val(data.value)
          target_value_input.trigger('change')
        location_option = target.find("select.location-selector option[value='#{data.location_id}']")
        location_input  = target.find("input.location-name")
        location_input.val(location_option.text())
        location_input.trigger('change')
      else
        target_value_input = target.find('[data-qelement-input=value]')
        if target_value_input.length > 0
          if target_value_input.attr('type') == 'checkbox'
            target_value_input.prop('checked', data.value == '1')
          else
            target_value_input.val(data.value)
            target_value_input.closest(".dropdown-select").find("[data-dropdown-value='#{data.value}']").trigger('click')
            target_value_input.trigger('change')
        target_value_alternative = target.find("input[type='radio'][value='#{data.value}']")
        if target_value_alternative.length > 0
          target_value_alternative.prop('checked', true)
          target_value_alternative.trigger('change')
        target_import_input = target.find("[ddata-qelement-input=import_from]")
        if (target_import_input.length > 0)
          target_import_input.val(data.id)
          target_file_input = target.find("input[type='file']")
          target_file_input.attr('data-setted', data.file.url.substr(data.file.url.lastIndexOf('/') + 1))
          target_file_input.trigger("change")

# restore_value
window.restore_value = (id)->
  current = $("[id='#{id}']")
  if current.length > 0
    current_html  = current.get(0)
    prev_value    = current_html.old_value
    if prev_value != undefined
      current_html.old_value = undefined
      new_value   = get_qelement_value(current)
      if (prev_value != new_value)
        window.disable_change_event = true
        set_qelement_basic_value(current, prev_value)
        window.disable_change_event = false

# pop_up_notice
pop_up_notice = (current)->
  pop_up_notice_data = current.data('pop-up-notice')
  if pop_up_notice_data
    current_value   = get_qelement_value(current)
    current_notice  = pop_up_notice_data[current_value] || pop_up_notice_data['*']
    if current_notice
      rollback_action = "restore_value('#{current.attr('id')}')"
      show_shared_modal("Please Note", current_notice, "Continue", "Go back", null, rollback_action, rollback_action)

$ ->
  force_update_show_when()
  $(document).on('change', '.qelement-show-section input, .qelement-show-section select, .qelement-show-section textarea', (e) ->
    current     = $(this)
    current_key = 'data-aelement-value'
    if current_key
      parent = current.closest('.qelement-show-section')
      parent.attr(current_key, current.val().toLowerCase())
      parent.attr('data-aelement-change', 'true')
      update_show_when()
  )

  $(document).on 'focus', '[data-pop-up-notice]', (e) ->
    this.old_value = get_qelement_value($(this)) if !window.disable_change_event

  $(document).on 'change', '[data-pop-up-notice]', (e) ->
    pop_up_notice($(this)) if !window.disable_change_event

  $(document).on 'keypress', '[data-pop-up-notice]', (e) ->
    if( e.charCode == 13 )
      e.preventDefault()
      $(this).blur()
