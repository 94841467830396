import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "name", "output" ]

  declare outputTarget: HTMLElement
  declare nameTarget: HTMLInputElement

  greet() {
    this.outputTarget.textContent =
      `Hello, ${this.nameTarget.value}!`
  }
}
