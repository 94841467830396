# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/


#$(window).scroll ->
#  $('.submit-area').css 'left', "calc(4.16% - #{$(window).scrollLeft() + 'px'})"
#  $('.submit-area').css 'width', "calc(95.84% + #{$(window).scrollLeft()+'px'})"
#  $('.submit-area').css 'right', 50 - $(window).scrollLeft() + 'px'
#  return

# It avoids case sensitive to search in a row.
$.extend $.expr[':'], 'containsIN': (elem, i, match, array) ->
  (elem.textContent or elem.innerText or '').toLowerCase().indexOf((match[3] or '').toLowerCase()) >= 0

$ ->
  $('#group_location_filter, #group_contact_filter').keyup ->
    $rows = $('tbody tr')
    val = $.trim(@value).toLowerCase()
    if val == ''
      $rows.show()
    else
      $rows.hide()
      $rows.has('td:containsIN(' + val + ')').show()
    return

  $(document).on 'change', '#select_all', ->
    element = $(this)
    switch element.prop('checked')
      when true
        $rows = $('tbody tr:not(.selected):visible')
      when false
        $rows = $('tbody tr.selected:visible')

    $rows.each ->
      $(this).find('input[type=checkbox]').prop('checked', element.is(':checked')).trigger("change")

  $(document).on 'click', '#group_locations td, #group_contacts td', ->
    $(this).parent('tr').find('input[type=checkbox]').prop('checked', !$(this).parent('tr').find('input[type=checkbox]').prop('checked')).trigger("change") unless $(this).hasClass('sel')

  $('#group_locations,  #group_contacts').on 'change', '[type="checkbox"]', ->
    $(this).closest('tr').toggleClass('selected')
