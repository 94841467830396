window.toggle_dynamic_action = (current) ->
  is_new = (current.length > 0) && ((current.val().toLowerCase() == 'new') || (current.val().toLowerCase() == 'i would like to register a new event organiser'))
  is_existing = (current.length > 0) && ((current.val().toLowerCase() == 'existing') || (current.val().toLowerCase() == 'i would like to use organiser information i have used before'))
  dynamic_object = current.closest(".dynamic-object-field")
  dynamic_object.find("input[data-dynamic-object-input='value']").toggle(is_new)
  dynamic_object.find("input[data-dynamic-object-input='value_for_existing']").closest('.dropdown.dropdown-select').toggle(is_existing)
  dynamic_object.closest('[data-question-id][data-question-type]').find('.dynamic-object-label').toggle( is_new || is_existing )

# Used to update the values of the dynamic object, and to show the information of the related questions
window.set_dynamic_object = (current) ->
  input_new = current.closest('.dynamic-object-field').find('[data-dynamic-object-input="value"]')
  if (current.prop('tagName') == 'select')
    selected = current.find(':selected')
    input_new.val(selected.html())
  else
    value = current.val()
    # Filtering of wrong values to avoid select, select… or select... values.
    if wrong_dynamic_object_values.includes(value)
      value = ''

    selected  = current.closest(".dropdown-select").find(".dropdown-menu .dropdown-select-item[data-dropdown-value='#{value}']")
    input_new.val(value).trigger('change')
  current_question_id = current.closest('[data-line-id][data-question-id]').attr('data-question-id')
  current_ql_id = current.closest('[data-line-id][data-question-id]').attr('data-line-id')
  dynamic_object_id = selected.attr('data-dynamic-object-id')
  if dynamic_object_id
    $(document).find('#spinner, #overlay').show()
    $.ajax({
      url: "/dynamic_objects/#{dynamic_object_id}.json",
      contentType: "application/json",
      dataType: "json",
      data: {'locale' : window.locale, 'questionnaire_line_id': current_ql_id},
      method: 'GET',
    }).done( (result)->
      if(result && result.data)
        content = JSON.parse result.data
        for question_id, line_data of content
          if question_id.toString() != current_question_id
            line_area = $("[data-line-id][data-question-id='#{question_id}']")
            set_question_value( line_area, line_data) if line_area.length > 0
      if current.closest("[data-question-alt=ado-existing-area]").length > 0
        list_for_checked()
      $(document).find('#spinner, #overlay').hide()
    ).fail( (result)->
      $(document).find('#spinner, #overlay').hide()
    )

window.load_dynamic_objects = () ->
  $('input[data-dynamic-object-input="action"]').each () ->
    toggle_dynamic_action($(this))
  $(document).on 'change', 'input[data-dynamic-object-input="value_for_existing"]', (e) ->
    set_dynamic_object($(this))
  $(document).on 'change', 'input[data-dynamic-object-input="action"]', (e) ->
    toggle_dynamic_action($(this))

window.list_for_checked = () ->
  target = $('#tab-sidebar-area a')
  target.each ()->
    current = $(this)
    name = current.attr("data-tab-id")

    switch name
      when 'shoots'
        shared_dialog_one_button("Production", "<b>Please note</b>: your application will now autofill with the saved information from the selected production. You will be taken directly to the shoot page where you can add the shoots for this application.", "Ok")
        update_sidebar_status(true)
        return true
      when 'event'
        shared_dialog_one_button("Event", "<b>Please note</b>: your application will now autofill with the saved information from the selected event. You will be taken directly to the event page where you can add the event for this application.", "Ok")
        update_sidebar_status(true)
        return true
      else
        current.closest('li').attr('data-is-new', 'false')

#Manages how dynamic radio buttons will behave when an interaction happens.
window.toggle_assisted_dynamic_radio = (current, targets, action_value_yes, action_value_no, use_label) ->
  target = ''
  targets.each (index, element) ->
    target = element.value if element.checked
  if (target.toLowerCase() == 'no')
    next_form_group = current.closest(".form-group").siblings('.form-group')
    next_question = next_form_group.find("[data-question-alt='#{action_value_no}']")
    current.toggle( "slow")
    next_question.toggle( "slow")
    $('[assisted_dynamic_object_label]').show() if use_label
    $(".wizard-reset").show()
  else
    # First case
    if action_value_yes == "no-value"
      $(".wizard-reset").show()
      id = $(".question-alt-userprv").attr("data-last-production-id")
      target = $(".value-ado-existing-area").find('.dropdown-menu').find("li")
      target.each ()->
        if $(this).attr("data-dynamic-object-id") == id
          name = $(this).attr("data-dropdown-value")
          $('.value-ado-existing-area').find("input[type='hidden']").val(name)
          $('.value-ado-existing-area').find("button").find(".selected_value").text(name)
          $('.value-ado-existing-area').find("input[type='hidden']").trigger("change");
    else
      next_form_group = current.closest(".form-group").siblings('.form-group')
      next_question = next_form_group.find("[data-question-alt='#{action_value_yes}']")
      current.toggle( "slow")
      next_question.toggle( "slow")
      $('[assisted_dynamic_object_label]').show()


window.hidden_components = () ->
  $('[data-question-alt="use-existing"]').hide()
  $('[data-question-alt="ado-existing-area"]').hide();
  $('[data-question-alt="ado-new-area"]').hide();
  $('[assisted_dynamic_object_label]').hide()
  $(".wizard-reset").hide()

window.clear_radio = (target) ->
  target.each (index, element) ->
    element.checked = false if element.checked

window.reset_value = () ->
  clear_radio($('.question-alt-userprv').find('input[type="radio"]'))
  clear_radio($('.question-alt-use-existing').find('input[type="radio"]'))
  $('.value-ado-new-area').find("input[type='text']").val("")
  $('.value-ado-new-area').find("input[type='text']").attr("value", "")
  $('.value-ado-existing-area').find("input[type='hidden']").val("")
  $('.value-ado-existing-area').find("button").find(".selected_value").text("Please select the production")
  #and state initial
  if ($('.assisted_dynamic_object').find(('[data-question-alt="use-prev"]')).length > 0)
    $('[data-question-alt="use-prev"]').show()
    hidden_components()
    current_tab_can_be_checked()

window.load_assisted_dynamic_object = () ->
  # initial state
  hidden_components() if ($('.assisted_dynamic_object').find(('[data-question-alt="use-prev"]')).length > 0)

  $('[data-question-alt][data-next-value-yes][data-next-value-no]').on 'change', (e)->
    current = $(this)
    targets = current.find('input[type="radio"]')
    action_value_yes = current.attr('data-next-value-yes')
    action_value_no  = current.attr('data-next-value-no')
    use_label = current.attr("data-question-alt") == 'use-existing'
    toggle_assisted_dynamic_radio(current,targets,action_value_yes, action_value_no, use_label)

  $(".wizard-reset").on 'click', (e)->
    e.preventDefault()
    reset_value()

# -----------------------------------------------------------------------------
# DYNAMIC ELEMENTS BASIC / EXTENDED
# -----------------------------------------------------------------------------

# Function: toggle_dynamic_element_action
window.toggle_dynamic_element_action = (current) ->
  if current.length > 0
    dynamic_object  = current.closest('.dynamic-object-element')
    if dynamic_object.length > 0
      current_val   = (current.length > 0) && current.val().toLowerCase()
      is_new        = (current_val == 'new')
      is_existing   = (current_val == 'existing')
      is_adoe       = dynamic_object.hasClass('dynamic-object-element-assisted')
      dynamic_object.find('input[data-dynamic-object-element-input="new_value"]').toggle(is_new)
      dynamic_object.find('input[data-dynamic-object-element-input="existing_value"]').closest('.dropdown.dropdown-select').toggle(is_existing)
      dynamic_object.closest('.qelement-show-area').find('.control-label.main-label').toggle( is_adoe || is_new || is_existing )
      dynamic_object.find('[data-adoe-area-on-no]:not(.adoe-initial-step),[data-adoe-area-on-yes]:not(.adoe-initial-step)').hide()
      dynamic_object.find('.adoe-initial-step').toggle(!(is_new || is_existing))
      dynamic_object.find('.adoe-reset-btn').toggle(is_new || is_existing)

# Function: update_assistant
window.update_assistant = (input) ->
  parent      = input.closest('.qelement-show-area')
  input_val   = input.val()
  attr_name   = "data-adoe-area-on-#{input_val.toLowerCase()}"
  target_area = input.closest("[#{attr_name}]")
  target      = if target_area then target_area.attr(attr_name) else null
  switch target
    when 'adoe_uses_new'
      action = parent.find('input[data-dynamic-object-element-input="action"]')
      action.val('new')
      action.trigger('change')
    when 'adoe_uses_existing'
      action = parent.find('input[data-dynamic-object-element-input="action"]')
      action.val('existing')
      action.trigger('change')
    when 'adoe_uses_last'
      action = parent.find('input[data-dynamic-object-element-input="action"]')
      action.val('existing')
      action.trigger('change')
    when 'adoe_shows_last_step'
      parent.find('.adoe-reset-btn').show()
      parent.find('.adoe-last-step').show()
      target_area.hide()

window.reset_assistant = (current) ->
  parent    = current.closest('.qelement-show-area')
  parent.find('[data-adoe-area-on-no] input,[data-adoe-area-on-yes] input').prop('checked', false);
  parent.find('input[data-dynamic-object-element-input]').val("")
  parent.find('input[data-dynamic-object-element-input="action"]').trigger("change")

window.set_dynamic_element = (current) ->
  selected = null
  if (current.prop('tagName') == 'select')
    selected  = current.find(':selected')
    value     = selected.attr('value')
  else
    value     = current.val()

    selected  = current.closest('.dropdown-select').find(".dropdown-select-item[data-dropdown-value=#{value}]")
  dynamic_object_id = selected && selected.attr('data-dynamic-object-id')
  if dynamic_object_id
    $.ajax({
      url: "/dynamic_objects/#{dynamic_object_id}.json",
      contentType: "application/json",
      dataType: "json",
      data: {'locale' : window.locale},
      method: 'GET',
    }).done( (result)->
      if(result && result.data)
        content = JSON.parse result.data
        for qname, qdata of content
          if qname.toString() != qdata.group
            qelement_areas = $("[data-qelement-name=#{qname}]")
            set_qelement_value(qelement_areas, qdata) if qelement_areas.length > 0
        # TODO: list_for_checked when main tab view is completed
        # if current.closest("[data-question-alt=ado-existing-area]").length > 0
        #   list_for_checked()
    ).fail( (result)->
      # console.log result
    )

# Function: load_dynamic_elements
window.load_dynamic_elements = ->
  $(document).on 'change', 'input[data-dynamic-object-element-input=existing_value]', (e) ->
    set_dynamic_element($(this))
  $(document).on 'change', 'input[data-dynamic-object-element-input=action]', (e) ->
    toggle_dynamic_element_action($(this))
  $(document).on 'change', '[data-adoe-area-on-no],[data-adoe-area-on-yes]', (e) ->
    update_assistant($(e.target))
  $(document).on 'click', '.adoe-reset-btn', (e) ->
    reset_assistant($(this))
  $('input[data-dynamic-object-element-input="action"]').trigger("change")

$ ->
  load_assisted_dynamic_object()
  load_dynamic_objects()
  load_dynamic_elements()
