class ButtonListener
  constructor: (elements...) ->
    window.elements = elements

  key_code_to_action = (focused_element, key_code) ->
    switch key_code
      when ' '
        focused_element.click()
      when 'Enter'
        focused_element.click()

  add_event_to_element = (element) ->
    element.keyup (e) ->
      key_code_to_action(element, e.key)

  setup_listeners: ->
    for button in window.elements
      add_event_to_element($(element)) for element in button

$ ->
  button_listeners = new ButtonListener($('.btn-application-submit'), $('.log-user'))
  button_listeners.setup_listeners()
