# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/

CalendarEntryFunc = {
  showCalendarEntryModal: (url, data, requestStatus, xhrObject) ->
    modal = $("#generic-big-modal").clone()
    if modal.length > 0
      modal.attr('id', 'calendar_entry_modal').addClass('calendar-entry-modal')
      splitted_url = url.split('/')
      action = splitted_url[splitted_url.length-1]
      action = action.substr(0, action.indexOf('?'))
      action = 'create' if action == 'new'
      modal.find(".modal-title").append('<h4>')
      modal.find('h4').text("#{action.capitalize()} Event")
      modal.find('.modal-dialog').removeClass('modal-lg')
      modal.find(".modal-body").html(data)
      modal.find('.modal-footer').html(modal.find('.delete-event-content'))
      modal.on('hidden.bs.modal', ()->
        $(this).data('bs.modal', null)
        $(this).remove()
      )
      modal.on('shown.bs.modal', ()->
        load_format_tools();
      )
      modal.find('form').on('submit', ()->
        $(this).find('input, textarea').attr('readonly', true)
        $(this).find("[name= 'commit'], select").prop('disabled', true)
      )
      modal.on('change', '[data-target]', ()->
        current = $(this)
        value = current.val()
        sibling_elements = current.siblings()
        if sibling_elements.length > 1
          element = sibling_elements.find('[name*='+ $(this).attr('data_target') + ']').val(value)
        else
          element = sibling_elements
        element.val(value)
      )
      window.active_calendar_modal = modal.modal("show")
  ,

  error: (xhrObj, textStatus, exception) ->
    alert('Sorry but something was wrong, please try again or contact your administrator. Error ID#JS_CA-EN_001')
  ,
  newCalendarEntry: ->
    $.ajax({
      url: $(this).attr('data-calendar-entry-url'),
      type: 'GET',
      data: instance = $(this).attr('instance'),
      timeout: 10000,
      success: (data, requestStatus, xhrObject) ->
        CalendarEntryFunc.showCalendarEntryModal($(this).attr('url'), data, requestStatus, xhrObject)
      ,
      error: CalendarEntryFunc.error,
    })
  ,
  setup: ()->
    $('.create-calendar-entry, .edit-calendar-entry').on('click', CalendarEntryFunc.newCalendarEntry)
}

$ ->
  CalendarEntryFunc.setup()

  $(document).on('ajax:error', '#new_calendar_entry, [id^=edit_calendar_entry]', (e, data, message)->
    $(this).find('input, textarea').attr('readonly', false)
    $(this).find("[name= 'commit'], select").prop('disabled', false)
  )

  $('tr[data-calendar-entry-url]').hover (->
    current = $(this)
    $.each($("tr[data-calendar-entry-url][data-search=#{current.attr('data-search')}]"), ()->
      $(this).toggleClass('hover')
    ))

  $('tr[data-main-url]').hover (->
    current = $(this)
    $.each($("tr[data-main-url='#{current.attr('data-main-url')}']"), ()->
      $(this).toggleClass('hover')
    ))
