# It fills a modal with information about the waiver functionality
# +action_on_yes+:: The action to be done when clicking on the accept button of the modal
# +action_on_cancel+:: The action to be done when clicking on the cancel button of the modal
window.show_waiver_modal = (title="", action_on_yes=null, action_on_no=null) ->
  modal = $("#shared_dialog")
  if modal.length > 0
    modal.find(".modal-content").addClass('waiver-content')
    modal.find(".modal-title").html(title)
    div_main = $('<div/>').addClass('body-waiver-section')
    main_text = $('<p/>').text("The applicant has applied for a waiver and has not paid to submit the application.")
    div = $('<div/>').addClass('confirmation-waiver-section')
    label = $('<label/>').addClass('confirmation-waiver-label').text("I confirm I have reviewed the application and wish to change the application status")
    checkbox = $('<input/>').attr(
      type: 'checkbox'
      name: 'waiver_confirmation').addClass('confirmation-waiver')

    modal.find(".modal-body").html(div_main)
    div_main.append main_text
    div_main.append div
    div.append(checkbox,label)

    for action_name, action_value of {yes: action_on_yes, no: action_on_no}
      button = modal.find(".option-#{action_name}")
      if action_name == "yes"
        button[0].disabled = true
        button.addClass('disabled-waiver')
      button.attr("data-action-on-click", action_value) if button

    modal.on 'shown.bs.modal', ()->
      $('body').on('click', {action_on_no: action_on_no}, handleModalCloseActionWaiver)
      $('body').on('click', "input[name='waiver_confirmation']", handleModalCheckboxAction)

    modal.on 'hidden.bs.modal', () ->
      $('body').off('click', handleModalCloseActionWaiver)

    modal.modal("show")

# It handles the waiver modal when it is being closed
# +event+:: event to close
handleModalCloseActionWaiver = (event)->
  if !$(event.target).closest('.modal').hasClass('in') && !$(event.target).is('.option-yes, .btn-accept')
    $('.body-waiver-section').remove()
    eval(event.data.action_on_no)

# This function controls the possibility to press the accept button of the waiver modal
# +event+:: event of clicking the checkbox of the modal
handleModalCheckboxAction = (event)->
  button = $("#shared_dialog").find(".option-yes")
  if event.target.checked
    button.attr('disabled', false)
    button.removeClass('disabled-waiver')
  else
    button.attr('disabled', true)
    button.addClass('disabled-waiver')

PaymentOnSubmission = {
  removeFee: ->
    fee_type =  $(this).attr('data-fee-type')
    $(this).siblings('input').attr('value', 1)
    $(this).parents('.form-group').find("[id ^= '#{fee_type}']").hide(500)
    $(this).fadeOut 500, ->
      $(this).remove()
    element = $('<div/>').hide().append("<p> Please click on submit to save your changes or cancel to discard. </p>")
    $(this).parent().append(element)
    element.show(1000)
  ,
  createNewFee: ->
    new_id = (new Date).getTime()
    regexp = new RegExp('new_' + $(this).attr('data-association'), 'g')
    element = $('<div/>').html($(this).attr('data-fields').replace(regexp, new_id)).hide()
    $(this).closest('.form-group').after(element)
    element.show(500)

    $(this).fadeOut 500, ->
      $(this).remove()

    element = $('<div/>').hide()
    $(this).parent().append(element)
    element.show(1000)
  ,
  error: (xhrObj, textStatus, exception) ->
    alert('Sorry but something was wrong, please try again or contact your administrator.')
  ,
  replaceTypeTaxesDiv: (data, requestStatus, xhrObject) ->
    $('#type_fee_options').hide()
    $('#type_fee_options').html(data).show(600)
  ,

  replace_the_div_waiver: (data, requestStatus, xhrObject) ->
    $('#type_waiver_options').hide()
    $('#type_waiver_options').html(data).show(600)
  ,

  getOptionsDiv: ->
    option = $(this).val()
    submission_fee_id = if typeof feeTypeID != 'undefined' then feeTypeID else null
    $.ajax({
      url: '/app_authorities/update_type_fee_options',
      type: 'GET',
      data: {selected_QL: option, fee_Id: submission_fee_id},
      dataType: 'html',
      timeout: 25000,
      success: PaymentOnSubmission.replaceTypeTaxesDiv,
      error: PaymentOnSubmission.error
    })
  ,

  get_options_div_waiver: ->
    option = $(this).val()
    $.ajax({
      url: '/app_authorities/update_waiver_answer_options',
      type: 'GET',
      data: {selected_questionnaire_line: option},
      dataType: 'html',
      timeout: 25000,
      success: PaymentOnSubmission.replace_the_div_waiver,
      error:   PaymentOnSubmission.error
    })

  setup: ->
    $(document).on('click', '.submission-fee', PaymentOnSubmission.createNewFee)
    $(document).on('click', '.remove-fee', PaymentOnSubmission.removeFee)
    $(document).on('change', '#type-fee-question', PaymentOnSubmission.getOptionsDiv)
    $(document).on('change', '#app_authority_waiver_questionnaire_line_id', PaymentOnSubmission.get_options_div_waiver)
}

$(PaymentOnSubmission.setup)
