class ApplicantMessageListener
  listenApplicantMessageSent: ->
    wording_element = $('.sent_message_wording')
    message_form = $('#edit_application_applicant')

    message_form.on 'ajax:success', (e, data, message)->
      if sent_message_wording_not_visible
        show_sent_message_wording()
        clear_message_form()

    message_form.on 'ajax:send', (e, data, message)->
      hide_sent_message_wording()

    show_sent_message_wording = ->
      wording_element.css({opacity: 0.0, visibility: "visible"}).animate({opacity: 1.0}, 700)

    clear_message_form = ->
      message_form.find('textarea').val('')

    hide_sent_message_wording = ->
      wording_element.css({visibility: "hidden"}) if sent_message_wording_visible

    sent_message_wording_not_visible = ->
      !sent_message_wording_visible

    sent_message_wording_visible = ->
      wording_element.css('visibility') == 'visible'

$ ->
  message_listener = new ApplicantMessageListener
  message_listener.listenApplicantMessageSent()