$ ->
  $(document).on("click", "[data-main-url] .fa-external-link.external-link", (e)->
    url = $(this).closest('[data-main-url]').attr('data-main-url')
    window.open(url, '_blank') if url
  )

  $('tr[data-main-url]').hover (->
    $('.fa-external-link.external-link', this).show()
    return
  ), ->
    $('.fa-external-link.external-link', this).hide()
    return
