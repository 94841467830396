# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/
#

window.CreateAutomaticInstanceFunc = {
  try_sending_form: (modal)->
    if !check_form(modal.find('form'))
      alert("We're sorry. Some fields are missing required data.")
    else
      modal.find('form').submit()
      modal.on('hide.bs.modal', (e) ->
        if $(this).attr('data-confirm-close') == 'true'
          $(this).attr('data-confirm-close', 'false')
        else
          false
      )
      modal.find(".navbar-btn").hide(1000)
      modal.find(".navbar-btn").fadeOut 1000, ->
        modal.find(".navbar-btn").remove()
      element = $('<div/>').hide()
      element.append("<div class='col-xs-2 text-left'><i class='fa fa-spinner fa-spin fa-2x' role='img'></i></div>")
      element.append("<div class='col-xs-22 text-left'> Cloning could possibly take several minutes. Please wait and don’t close your browser. You will be redirected when the process finishes.</div>")

      modal.find(".modal-footer").append(element)
      element.show(1000)
  ,
  error: (xhrObj, textStatus, exception) ->
    alert('Sorry but something was wrong, please try again or contact your administrator. Error ID#JS_AI-EN_001')
  ,
  showAppAuthoritySelector: ->
    $.ajax({
      url: $(this).attr('data-app-instance-selector-url'),
      type: 'GET',
      data:
        application: $(this).attr('application'),
        extra_params: $(this).attr('extra-data'),
        current_authority: window.current_authority,
        current_app: window.current_app
      timeout: 10000,
      success: (data, requestStatus, xhrObject) ->
        show_shared_modal('Please select the app and authority from which you want to clone the questions.', data, "Accept", "Cancel", "CreateAutomaticInstanceFunc.try_sending_form($(this).closest('.modal'))", null, null, '#generic-modal')
      ,
      error: CreateAutomaticInstanceFunc.error,
    })
  ,
  setup: ()->
    $('#create-instance-btn').on('click', CreateAutomaticInstanceFunc.showAppAuthoritySelector)
}

window.auto_display_settings = (element, do_not_swap = false)->
  sanitized_id = ''
  $("[show_when]").each ->
    if element.attr('id').indexOf($(this).attr('show_when')) >= 0
      sanitized_id = element.attr('id').substring(element.attr('id').indexOf($(this).attr('show_when')), element.attr('id').indexOf($(this).attr('show_when')) + $(this).attr('show_when').length)
      return false
  element.prop("checked", !element.prop("checked")) unless do_not_swap
  if element.is(':checked')
    $("[show_when$=#{sanitized_id}]").closest('.form-group').show(400)
    $("[show_when$=#{sanitized_id}]").attr('reset', false)
  unless element.is(':checked')
    $("[show_when$=#{sanitized_id}]").closest('.form-group').hide(400)
    $("[show_when$=#{sanitized_id}]").attr('reset', true)

if !String::startsWith
  String::startsWith = (searchString, position) ->
    position = position or 0
    window.indexOf(searchString, position) == position

$ ->
  setTimeout(()->
    $('.info-app-panel').css('min-height', $('.grid-area').height())
  , 200)

  $(document).on('submit', 'form', (e)->
    attr = $(this).attr('id')
    if (typeof attr != typeof undefined && attr != false)
      if $(this).attr('id').startsWith('edit_app_authority')
        $("[reset='true']").each ()->
          switch $(this).attr('type')
            when 'checkbox'
              $(this).val('0')
            else
              if $(this).attr('id') == 'app_authority_enquiry_name' then $(this).val('Enquiry') else $(this).val('')
  )

  $('tbody').find("[data-main-url='#{location.pathname + location.search}']").toggleClass('active-tr inactive-tr')

  $(document).on('click', '#app_authority_enquiry_available', (e)->
    e.preventDefault()
    show_shared_modal("Warning", "To enable enquiries in this instance please speak to Secret Source to review the JSON layout_section in the instance question tab.", "Ok" , 'No' , "auto_display_settings($(document).find('#app_authority_enquiry_available'))" , "")
  )
  $(document).on('click', '#app_authority_allow_split_payment', (e)->
    auto_display_settings($(document).find('#app_authority_allow_split_payment'), true)
  )
  $(document).on('click', '#app_authority_allow_waivers', (e)->
    auto_display_settings($(document).find('#app_authority_allow_waivers'), true)
  )

  window.CreateAutomaticInstanceFunc.setup()
