#$ ->
#  $checkbox = $('[id^="I_agree_to_the_Apply4_T_Cs"]')
#  $button = $('[id="new_user"]').find('[type = "submit"]')
#  $button.prop('disabled', !$checkbox.is(':checked'))
#
#  $checkbox.change ()->
#    $button.prop('disabled', !$checkbox.is(':checked'))

window.set_mobile_password_user_to_blank = (e)->
  if $('body').hasClass("users_edit")
    mobile_number = $('[name="user[mobile]"]').val()
    fields = $('[name="user[password]"],[name="user[mobile]"]')
    fields.each ->
      current_element = $(this)
      switch current_element[0].type
        when "password"
          current_element.val('')
        else
          current_element.val(mobile_number)

    return

$ ->
  $checkbox = $('[id^="I_agree_to_the_Apply4_T_Cs"]')
  $button = $('[id="new_user"]').find('[type = "submit"]')
  $button.click (e)->
    if $checkbox.length > 0 && !$checkbox.is(':checked')
      action_on_yes = "$('[id^=\"I_agree_to_the_Apply4_T_Cs\"]').click(); $('[id^=\"I_agree_to_the_Apply4_T_Cs\"]').parent().effect( \"highlight\", {color:\"rgba(30, 171, 244, 0.2)\"}, 4000 )"
      action_on_no = "$('[id^=\"I_agree_to_the_Apply4_T_Cs\"]').parent().effect( \"highlight\", {color:\"rgb(237, 175, 175)\"}, 4000 )"
      show_shared_modal("", "Please accept terms and conditions before proceeding with registration.", "Yes", "No", action_on_yes=action_on_yes, action_on_no = action_on_no)
      e.preventDefault()

  set_mobile_password_user_to_blank()
