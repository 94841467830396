class ApplicationsItemListener
  constructor: (elements) ->
    window.elements = elements

  key_code_to_action = (focused_element, key_code) ->
    switch key_code
      when ' '
        focused_element.click()
      when 'Enter'
        focused_element.click()

  add_event_to_element = (element) ->
    element.keyup (e) ->
      key_code_to_action(element, e.key)

  setup_listeners: ->
    add_event_to_element($(element)) for element in window.elements

$ ->
  applications_item_listener = new ApplicationsItemListener($('.row-with-status'))
  applications_item_listener.setup_listeners()

