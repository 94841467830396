showMessagesList = {
  hideMessagesList: ->
    $('#messages-lateral-panel').hide()
    $(window).off('click')
    $('dropdown').off('show.bs.dropdown')
  ,
  showMessagesDiv: (data, requestStatus, xhrObject) ->
    $('#top-messages').children().addClass('open') unless $('#top-messages').children().hasClass('open')
    $('#messages-lateral-panel').html(data).show()
    $(window).on('click', (e)->
      if !$(e.toElement).closest('.internal-messages-panel').length > 0 || $(e.toElement)[0].hasAttribute('data-close-internal-messages')
        showMessagesList.hideMessagesList()
      else
        $('#top-messages').children().addClass('open') unless $('#top-messages').children().hasClass('open')
    )
    $('.dropdown').on('show.bs.dropdown', (e)->
      showMessagesList.hideMessagesList()
    )
  ,
  updateMessagesDiv: (data, requestStatus, xhrObject) ->
    $('#messages-lateral-panel').html(data).show()
  ,
  getMessagesList: ->
    $.ajax({
      url: '/internal_messages/summary',
      type: 'POST',
      data:
        message_type: $(this).attr('id'),
        current_authority: window.current_authority,
        current_app: window.current_app,
        applications: window.applicationsIDList
      ,
      dataType: 'html',
      timeout: 10000,
      success: showMessagesList.showMessagesDiv
      error: showMessagesList.error
    })
  setup: ->
    $('#top-messages').on('click', showMessagesList.getMessagesList)
  ,
  error: (xhrObj, textStatus, exception) ->
    alert('Sorry but something was wrong, please try again or contact your administrator.')
}

mark_read = (message)->
  mark_read_url = message.attr('data-mark-read-url')
  $.ajax({
    url: mark_read_url,
    type: 'GET',
    success: ()->
      message.attr('data-message-status', 'read')
      active_not_read_mark = $('.nav.admin-messaging-nav .active .message-mark.status-not-read')
      active_not_read_mark.toggleClass('status-not-read status-read') if (active_not_read_mark.length > 0) && (! $("[data-message-status='not-read']:visible").length > 0)
    error: ()->
      true
  })

window.set_new_message_submit_availability = (content_length) ->
  container_parent = $('#new_message')
  if no_form_errors(container_parent, content_length)
    activate_new_message_submit_button(container_parent)
  else
    deactivate_new_message_submit_button(container_parent)

window.set_internal_message_submit_availability = (target) ->
  container_parent = target.parentNode
  content_length = target.innerText.length
  if content_length > 0
    activate_internal_message_submit_button(container_parent)
  else
    deactivate_internal_message_submit_button(container_parent)

no_form_errors = (container_parent, content_length) ->
  recipient_validator_error = container_parent.find('.recipient-error').length

  content_length > 0 && recipient_validator_error == 0

window.block_empty_fields = (container_id) ->
  $('#new_message').find('input.btn-lg').attr('disabled', 'disabled')
  deactivate_every_internal_message()

deactivate_every_internal_message = () ->
  internal_message_editors = $('.internal-message-edit-content')
  for editor in internal_message_editors
    $(editor).find($('[type="submit"], .action-send')).attr('disabled', 'disabled')

activate_new_message_submit_button = (container_parent) ->
  container_parent.find('input.btn-lg').attr('disabled', null)

deactivate_new_message_submit_button = (container_parent) ->
  container_parent.find('input.btn-lg').attr('disabled', 'disabled')

activate_internal_message_submit_button = (container_parent) ->
  container_parent.querySelector('span.action-send').removeAttribute('disabled')

deactivate_internal_message_submit_button = (container_parent) ->
  container_parent.querySelector('span.action-send').setAttribute('disabled', 'disabled')

$ ->
  showMessagesList.setup()
  $(document).on('click', '.internal-message-show-item .message-toggle-icon, .internal-message-admin-show-item .message-toggle-icon', (e)->
    message_icon = $(this)
    item = message_icon.closest('.internal-message-show-item, .internal-message-admin-show-item')
    item.toggleClass('internal-message-collapsed internal-message-expanded')
    current_title = message_icon.attr('title')
    toggle_title_attr(message_icon, 'open')
    message_icon.toggleClass('open')
  )

  $(document).on('submit', '.new-message-container #new_message', (e)->
    current = $(this)
    current.find('[type="submit"]').attr('disabled','disabled')
  )

  $(document).on('click', '.modal [data-modal-accept-button="single-click"]', (e)->
    current = $(this);
    current.attr('disabled','disabled')
  )

  $(document).on('click', "#new-message-link, #new-discussion-message-link", (e)->
    previous_message_title_open = window.message_title_open
    window.message_title_open = $(this).attr('id')

    main_collapsable = $('#new-message-area')
    main_collapsable_expanded = main_collapsable.attr('aria-expanded') && main_collapsable.attr('aria-expanded') == 'true'
    main_collapsable.collapse('show') unless main_collapsable_expanded

    has_to_be_closed = main_collapsable_expanded && previous_message_title_open == window.message_title_open

    $(this).siblings('span').removeClass('active')
    $(this).addClass('active')

    if has_to_be_closed
      main_collapsable.collapse('hide')
    else
      switch $(this).attr('id')
        when 'new-message-link'
          $('#internal-header').show(200)
          $('#discussion-header').hide(200)
        when 'new-discussion-message-link'
          $('#discussion-header').show(200)
          $('#internal-header').hide(200)
      chosen_type = $(this).attr('type')
      $('#message_internal_message_type').val(chosen_type)
  )

  $(document).on('hidden.bs.collapse', '.internal-message-container-form #new-message-area', (e)->
    $(this).closest('#messages_collapsable').find('.message-section-head .active').removeClass('active')
  )

  $(document).on('show.bs.collapse', '.internal-message-container-form #new-message-area', (e)->
    textarea = $('.full-mail-contacts-text')
    textarea.val('')
  )

  # Collapse show/hide
  $(document).on('hidden.bs.collapse', '.attachments-list', (e)->
    current = $(this)
    target = current.closest('.action-area.top-area').find('#attachments-list-link span.glyphicon')
    toggle_title_attr(target, 'glyphicon-triangle-bottom')
    if(target.hasClass('glyphicon-triangle-top'))
      target.toggleClass('glyphicon-triangle-bottom glyphicon-triangle-top')
  )

  $(document).on('show.bs.collapse', '.attachments-list', (e)->
    current = $(this)
    target = current.closest('.action-area.top-area').find('#attachments-list-link span.glyphicon')
    toggle_title_attr(target, 'glyphicon-triangle-bottom')
    if(target.hasClass('glyphicon-triangle-bottom'))
      target.toggleClass('glyphicon-triangle-bottom glyphicon-triangle-top')
  )

  # Tabpanel show/hide
  $(document).on('shown.bs.tab', '.admin-messaging-nav a[data-toggle="tab"]', (e)->
    current = $(this)
    target = $(e.target).attr('href')
    target = $(target).find('.internal-message-admin-show-item:last')
    if(!target.hasClass('internal-message-expanded'))
      target.find('.message-toggle-icon').click()
  )


  $(document).on('change', '[data-on-change-target]', (e)->
    current = $(this)
    name = current.val()
    name = name.split('\\')
    name = name[name.length - 1]
    name = "Attach a document" if !(name.length > 0)
    target = current.attr('data-on-change-target')
    target = $("##{target}")
    target.html(name)
  )


  $(document).on('click', '.internal-message-show-item[data-message-status="not-read"] .message-toggle-icon, .internal-message-admin-show-item[data-message-status="not-read"] .message-toggle-icon', ()->
    current = $(this)
    mark_read(current.closest('[data-message-status="not-read"]'))
  )

  $(document).on('click', '#new_message .action-reset', (e) ->
    container_parent = $('#new_message')
    clicked_editor = $('#new_message trix-editor')
    clicked_editor[0].textContent = ''
    deactivate_new_message_submit_button(container_parent))


  $(document).on('click', '.internal-message-edit-content .action-reset', (e) ->
    container_parent = e.target.parentNode
    clicked_editor = e.target.parentNode.previousElementSibling
    clicked_editor.textContent = ''
    deactivate_internal_message_submit_button(container_parent))


  for instance in $('.trix-content')
    block_empty_fields(instance.id)
    is_new_message = (instance.id == 'message_notes')

    if is_new_message
      instance.addEventListener('keyup', (e) -> set_new_message_submit_availability(e.target.innerText.length))
    else
      instance.addEventListener('keyup', (e) -> set_internal_message_submit_availability(e.target))

  current_message = if(window.current_message) then $("[data-code='#{window.current_message}']") else null
  if(current_message && current_message.length > 0)
    panel = current_message.closest('.tab-pane')
    list_item = $(".admin-messaging-list-area .admin-messaging-nav a[href='##{panel.attr("id")}']")
    list_item.click()
    setTimeout(()->
      if(!current_message.hasClass('internal-message-expanded'))
        current_message.find('.message-toggle-icon').click()
    , 200)
  else
    $(document).on('click', '[data-tab-id="messages"]', (e)->
      $('[href=#new-message-area]').click() if !$('.admin-messaging-list-area .admin-messaging-nav li').length > 0
      $('.admin-messaging-nav a:first').click()
      $('.internal-message-show-item.internal-message-collapsed:last .message-toggle-icon').click()
    )
    if(!($('[data-tab-id="messages"]').length > 0))
      $('.internal-message-show-item.internal-message-collapsed:last .message-toggle-icon').click()
